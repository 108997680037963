import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../service/user.service';
import { Machine } from '../../../models/machine.model';
import { MachineService } from '../../../service/machine.service';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent {

    public form: FormGroup;
    public machines: Machine[] = [];
    
    status = ['ADMIN', 'MANAGER', 'SUPERADMIN'];

    constructor(
        private _router: Router,
        public userService: UserService,
        private snackBar: SnackbarService,
        private _translateService: TranslateService,
        private machineService: MachineService,
        public fb: FormBuilder
    ) {
        this.form = new FormGroup({
            login: new FormControl('', [Validators.required]),
            password: new FormControl('', [Validators.required]),
            name: new FormControl('', [Validators.required]),
            lastname: new FormControl('', [Validators.required]),
            status: new FormControl('', [Validators.required]),
            machines: new FormArray([this.createMachineArray()])
        });

        this.machineService.getMachines([]).subscribe({
            next: (machines) => {
                this.machines = machines;
            },
            error: (e) => console.error(e)
        });
    }

    public createUser(): void {
        if (this.form.valid) {
            let data = this.form.value;
            let formData = new FormData();
            formData.append('login', data.login);
            formData.append('password', data.password);
            formData.append('name', data.name);
            formData.append('lastname', data.lastname);
            formData.append('status', data.status);
            formData.append('machines', JSON.stringify(data.machines));

            this.userService.createUser(formData).subscribe({
                next: (user) => {
                    this._router.navigate(['admin/users']);
                    this.snackBar.openSnackBar(this._translateService.instant('MODULES.ADMINISTRATION.BUILDING.FORM.SUCCESS'), 'Success', 5000);
                },
                error: (e) => {
                    console.error(e);
                    this.snackBar.openSnackBar(e.error.message, 'Failure', 5000);
                }
            });
        }
    }

    public cancel(): void {
        this._router.navigate(
            ['admin/users']
        );
    }

    createMachineArray() {
      return new FormGroup({
        'machine' : new FormControl('')
      });
    }

    get MachineFormControls() {
      return (this.form.get('machines') as FormArray).controls;
    }

    addMoreMachine(){
      const machineForm = this.form.get('machines') as FormArray;
      machineForm.push(this.createMachineArray());
    }
  
    deleteMachine(index: any) {
      const machineForm = this.form.get('machines') as FormArray;
      machineForm.removeAt(index);
    }

}
