import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommandService {

  protected URL: string = environment.backendApi;
  constructor(private httpClient: HttpClient, private storage: StorageService) { }

  public getToken(){
    let data = this.storage.get('user');
    return data;
   }

   public getAllTransactions(): Observable<any> {
    let loginData = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loginData.token}`
      })
    };
      return this.httpClient.get<any>(`${this.URL}/admin/command/list`, httpOptions);
  }
 

  public getTransaction(transactionId : string): Observable<any> {
    let loginData = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loginData.token}`
      })
    };
      return this.httpClient.get<any>(`${this.URL}/admin/command/${transactionId}`, httpOptions);
  }

  public updateCommandStatus(transaction: any): Observable<any> {
    let loginData = this.getToken();
    const body ={
      status: transaction.status
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/x-www-form-urlencoded',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loginData.token}`
      })
    };
      return this.httpClient.put<any>(`${this.URL}/admin/command/${transaction.id}`, body, httpOptions);
  }

  public createTransaction(transactionDetail: any): Observable<any> {
    let loginData = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/x-www-form-urlencoded',
        'enctype': 'multipart/form-data',
        'Authorization': `Bearer ${loginData.token}`
      })
    };
      return this.httpClient.post<any>(this.URL+'/admin/command/create', transactionDetail, httpOptions);
  }

  public getProducts(): Observable<any> {
    let loginData = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loginData.token}`
      })
    };
      return this.httpClient.get<any>(`${this.URL}/common/product_list`, httpOptions);
  }
}
