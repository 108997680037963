import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { BuildingService } from '../../../../service/building.service';
import { TranslateService } from '@ngx-translate/core';
import { MachineService } from 'src/app/modules/administration/service/machine.service';

@Component({
  selector: 'app-machine-add',
  templateUrl: './machine-add.component.html',
  styleUrls: ['./machine-add.component.scss']
})
export class MachineAddComponent implements OnInit {

    public form: FormGroup;

    locales = ['fr', 'en'];
    yesNoValues = ['YES', 'NO'];
    systems = ['NONE', 'HEITZ', 'SPORTIGO', 'DECIPLUS', 'VIRTUAL_GYM', 'RESAMANIA', 'FITNESS_PARK'];
    buildingList = [];

    constructor(
        private _router: Router,
        private buildingService: BuildingService,
        private machineService: MachineService,
        private snackBar: SnackbarService,
        private _translateService: TranslateService
    ) {
        this.form = new FormGroup({
            label: new FormControl('', [Validators.required]),
            description: new FormControl(),
            locale: new FormControl(),
            paymentCB: new FormControl(),
            waterAvailable: new FormControl(),
            waterFree: new FormControl(),
            contact: new FormControl(),
            building: new FormControl(),
            system: new FormControl(),
            badgeBoxCode: new FormControl(),
            apiKey: new FormControl(),
            apiUrl: new FormControl(),
            codeCentre: new FormControl(),
            clientId: new FormControl(),
            heitzKey: new FormControl(),
            idArticle: new FormControl(),
            idTarrif: new FormControl(),
            idPayment: new FormControl(),
            clientSecret: new FormControl(),
            domain: new FormControl(),
            zoneId: new FormControl(),
            companyId: new FormControl(),
            clientToken: new FormControl(),
            clubId: new FormControl(),
            nodeId: new FormControl(),
            clubCode: new FormControl(),
            machinePort: new FormControl('/dev/ttyS7'),
            paymentPort: new FormControl('/dev/ttyS9')
        });
    }

    ngOnInit() {
        this.buildingService.getBuildings([]).subscribe((resp: any) => {
            this.buildingList = resp;
        })
    }

    public generateApiKey(): void {
        var apiKey = this.makeid(50);
        this.form.patchValue({apiKey: apiKey});
    }

    private makeid(length: number) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    }

    public createMachine(): void {
        if (this.form.valid) {
            let data = this.form.value;
            let formData = new FormData();
            formData.append('label', data.label);
            formData.append('description', data.description);
            formData.append('locale', data.locale);
            formData.append('paymentCB', data.paymentCB === 'YES' ? 'true' : 'false');
            formData.append('waterAvailable', data.waterAvailable === 'YES' ? 'true' : 'false');
            formData.append('waterFree', data.waterFree === 'YES' ? 'true' : 'false');
            formData.append('contact', data.contact);
            formData.append('building', data.building);
            formData.append('machinePort', data.machinePort);
            formData.append('paymentPort', data.paymentPort);
            formData.append('system', data.system);

            if (data.system == 'HEITZ') {
                formData.append('badgeBoxCode', data.badgeBoxCode);
                formData.append('apiKey', data.apiKey);
                formData.append('clientId', data.clientId);
                formData.append('heitzKey', data.heitzKey);

                let heitzData: any = {};
                heitzData.api = data.apiUrl;
                heitzData.codeCentre = data.codeCentre;
                heitzData.clientId = data.clientId;
                heitzData.clientKey = data.heitzKey;
                heitzData.idArticle = data.idArticle;
                heitzData.idTarrif = data.idTarrif;
                heitzData.idPaiement = data.idPayment;
                
                formData.append('config', JSON.stringify(heitzData));
            } else if (data.system == 'DECIPLUS') {

                let deciplusData: any = {};
                deciplusData.clientId = data.clientId;
                deciplusData.clientSecret = data.clientSecret;
                deciplusData.domain = data.domain;
                deciplusData.zoneId = data.zoneId;
                
                formData.append('config', JSON.stringify(deciplusData));
            } else if (data.system == 'SPORTIGO') {

                let sportigoData: any = {};
                sportigoData.companyId = data.companyId;
                sportigoData.apiKey = data.apiKey;
                
                formData.append('config', JSON.stringify(sportigoData));
            } else if (data.system == 'RESAMANIA') {

                let resamaniaData: any = {};
                resamaniaData.clientToken = data.clientToken;
                resamaniaData.apiKey = data.apiKey;
                resamaniaData.clientId = data.clientId;
                resamaniaData.clientSecret = data.clientSecret;
                resamaniaData.clubId = data.clubId;
                resamaniaData.nodeId = data.nodeId;
                resamaniaData.clubCode = data.clubCode;
                
                formData.append('config', JSON.stringify(resamaniaData));
            }

            this.machineService.addMachine(formData).subscribe({
                next: (machineCreated) => {
                    this._router.navigate(['admin/machines']);
                    this.snackBar.openSnackBar(this._translateService.instant('MODULES.ADMINISTRATION.MACHINE.FORM.SUCCESS'), 'Success', 5000);
                },
                error: (e) => {
                    console.error(e);
                    this.snackBar.openSnackBar(e.error.message, 'Failure', 5000);
                }
            });
        }
    }

    public cancel(): void {
        this._router.navigate(
            ['admin/machines']
        );
    }

}
