import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { DialogContentComponent } from '../dialog-content/dialog-content.component';
import { CommandService } from '../../service/command.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
@Component({
  selector: 'app-command',
  templateUrl: './command.component.html',
  styleUrls: ['./command.component.scss']
})
export class CommandComponent implements OnInit {
  @ViewChild(MatTable, { static: true }) table: MatTable<any> = Object.create(null);
  userData: any;
  displayedColumns: string[] = [
    'id',
    'commandNumber',
    'code',
    'name',
    'status',
    'actions',
  ];
  commandData: any = null;
  dataSource = new MatTableDataSource();
  public user: any;

  constructor(private dialog: MatDialog, private router: Router, private storage: StorageService, private CommandService: CommandService , private snackBar: SnackbarService) {

    this.user = this.storage.get('user');
   }

  ngOnInit(): void {
    this.CommandService.getAllTransactions().subscribe({
      next: (commands) => {
        this.dataSource.data = commands;
        this.dataSource._updateChangeSubscription();
      },
      error: (e) => console.error(e)
    });
        this.userData = this.storage.get('user');

  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  btnCategoryClick(val: string): number {
    this.dataSource.filter = val.trim().toLowerCase();
    return this.dataSource.filteredData.length;
  }

  getTransactionByID(action: string, id: string): void {
    this.CommandService.getTransaction(id).subscribe({
      next: (command) => {
        this.commandData = command;
        this.commandData['action'] = action;
      },
      error: (e) => console.error(e)
    });
  }

  openDialog(action: string, obj?: any): void {
    if (action !== 'Add') {
      this.getTransactionByID(action, obj.id);
    }
      setTimeout(() => { 
        if (this.commandData || action === 'Add') {
          const dialogRef = this.dialog.open(DialogContentComponent, {
          data: {data: obj ? this.commandData : action, type: 'command'},
          disableClose: false,
          width: '800px',
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result.event === 'Add') {
            this.addRowData(result.data);
          } else if (result.event === 'Update') {
            this.updateRowData(result.data);
          } 
        });
      }
      }, 1500);
  }

  reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/admin/command']);
}
  // tslint:disable-next-line - Disables all
  addRowData(data: any): void {
    let formData = new FormData();
      formData.append('command_number', data.commandNumber);
      formData.append('building_id', data.buildingID);
      formData.append('price', data.price);
      formData.append('tva', data.tva);
      formData.append('products', JSON.stringify(data.products));
    this.CommandService.createTransaction(formData).subscribe({
      next: (command) => {
        this.snackBar.openSnackBar('La commande a bien été crée', '', 5000)
        this.reloadComponent()
      },
      error: (e) => this.snackBar.openSnackBar(e.error.message, 'Failure', 5000)

    });

  }

  // tslint:disable-next-line - Disables all
  updateRowData(row_obj: any): boolean | any {
    this.CommandService.updateCommandStatus(row_obj).subscribe({
      next: (command) => {
        this.snackBar.openSnackBar('La commande a bien été modifiée', '', 5000)
        this.reloadComponent()
      },
      error: (e) => this.snackBar.openSnackBar(e.error.message, 'Failure', 5000)
    }); 
  }

  public isAdmin (): boolean {
      return this.user?.status == 'ADMIN' || this.user?.status == 'SUPERADMIN';
  }

  public formatStatus (status: string): string {
      if (status == 'PAID') {
        return 'Payée';
      }
      if (status == 'WAITING_FOR_VALIDATION') {
        return 'En attente de validation';
      }
      if (status == 'WAITING_FOR_PAYMENT') {
        return 'En attente de paiement';
      }
      return status;
  }
}


