<div class="row">
    <div class="machine-info">
        <h2 class="title">Stats</h2>
        <mat-expansion-panel [expanded]="true" class="expanded-panel">
            <mat-expansion-panel-header>
                <mat-panel-title> Stats global</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="stat-info" *ngIf="stat">
                <table>
                    <thead>
                        <tr>
                            <th>Total dose vendu</th>
                            <th>Dose moyenne journalière</th>
                            <th>Total eau</th>
                            <th>eau moyenne journalière</th>
                            <th>Prix total</th>
                            <th>Prix moyen journalier</th>
                            <th>Produit le plus vendu</th>
                            <th>Produit le moins vendu</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ stat.totalDose }}</td>
                            <td>{{ stat.averageDailyDoses }}</td>
                            <td>{{ stat.totalWater }}</td>
                            <td>{{ stat.averageDailyWater }}</td>
                            <td>{{ formatPrice(stat.totalPrice) }}</td>
                            <td>{{ formatPrice(stat.averageDailyPrice) }}</td>
                            <td>{{ formatProductName(stat.bestProduct) }}</td>
                            <td>{{ formatProductName(stat.lessProduct) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <h3 class="title">Dose vendu par produit</h3>
                <div class="table-container2">
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Total dose vendu</th>
                                <th>Dose moyenne journalière</th>
                                <th>Prix total</th>
                                <th>Prix moyen journalier</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let productStat of productStats">
                                <td>{{ formatProductName(productStat.productCode) }}</td>
                                <td>{{ productStat.totalDose }}</td>
                                <td>{{ productStat.averageDailyDoses }}</td>
                                <td>{{ formatPrice(productStat.totalPrice) }}</td>
                                <td>{{ formatPrice(productStat.averageDailyPrice) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="expanded-panel">
            <mat-expansion-panel-header>
                <mat-panel-title> Stats mensuel</mat-panel-title>
            </mat-expansion-panel-header>
            <h3>Ventes</h3>
            <div>
                <div class="table-container2">
                    <table>
                        <thead>
                            <tr>
                                <th>Salle</th>
                                <th>Janvier</th>
                                <th>Fevrier</th>
                                <th>Mars</th>
                                <th>Avril</th>
                                <th>Mai</th>
                                <th>Juin</th>
                                <th>Juillet</th>
                                <th>Aout</th>
                                <th>Septembre</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let machine of activeMachines">
                                <td>{{ machine.label }}</td>
                                <td *ngFor="let month of months">
                                    <div>
                                        <ng-template #machineDose
                                            [ngTemplateOutlet]="machineDose"
                                            let-machineDose
                                            [ngTemplateOutletContext]="{ $implicit: getMachineDosesDiffByMonth(machine, month) }">
                                            <p>Doses : 
                                                <span>{{ getMachineDosesByMonth(machine, month)}}</span>
                                                <span *ngIf="machineDose > 10" class="positiv-value"> ( +{{ machineDose }})</span>
                                                <span *ngIf="machineDose > 0 && machineDose <= 10" class="equals-value"> ( +{{ machineDose }})</span>
                                                <span *ngIf="machineDose < 0 && machineDose >= -10" class="equals-value"> ( {{ machineDose }})</span>
                                                <span *ngIf="machineDose < -10" class="negativ-value"> ( {{ machineDose }})</span>
                                            </p>
                                        </ng-template>

                                        <ng-template #machinePrice
                                            [ngTemplateOutlet]="machinePrice"
                                            let-machinePrice
                                            [ngTemplateOutletContext]="{ $implicit: getMachinePricesDiffByMonth(machine, month) }">
                                            <p>Prix : 
                                                <span>{{ getMachinePriceByMonth(machine, month)}}</span>
                                                <span *ngIf="machinePrice > 1000" class="positiv-value"> ( +{{ formatPrice(machinePrice) }})</span>
                                                <span *ngIf="machinePrice > 0 && machinePrice <= 1000" class="equals-value"> ( +{{ formatPrice(machinePrice) }})</span>
                                                <span *ngIf="machinePrice < 0 && machinePrice >= -1000" class="equals-value"> ( {{ formatPrice(machinePrice) }})</span>
                                                <span *ngIf="machinePrice < -1000" class="negativ-value"> ( {{ formatPrice(machinePrice) }})</span>
                                            </p>
                                        </ng-template>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <h3>Utilisateurs</h3>
            <div>
                <div class="table-container2">
                    <table>
                        <thead>
                            <tr>
                                <th>Salle</th>
                                <th>Janvier</th>
                                <th>Fevrier</th>
                                <th>Mars</th>
                                <th>Avril</th>
                                <th>Mai</th>
                                <th>Juin</th>
                                <th>Juillet</th>
                                <th>Aout</th>
                                <th>Septembre</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let machine of activeMachines">
                                <td>{{ machine.label }}</td>
                                <td *ngFor="let month of months">
                                    <div>
                                        <ng-template #machineClient
                                            [ngTemplateOutlet]="machineClient"
                                            let-machineClient
                                            [ngTemplateOutletContext]="{ $implicit: getMachineUsersDiffByMonth(machine, month) }">
                                            <p>client : 
                                                <span>{{ getMachineUserByMonth(machine, month)}}</span>
                                                <span *ngIf="machineClient > 0" class="positiv-value"> ( +{{ machineClient }})</span>
                                                <span *ngIf="machineClient < 0" class="negativ-value"> ( {{ machineClient }})</span>
                                            </p>
                                        </ng-template>
                                        <ng-template #machineSub
                                            [ngTemplateOutlet]="machineSub"
                                            let-machineSub
                                            [ngTemplateOutletContext]="{ $implicit: getMachineSubscriberDiffByMonth(machine, month) }">
                                            <p>abonnés : 
                                                <span>{{ getMachineSubscriberByMonth(machine, month)}}</span>
                                                <span *ngIf="machineSub > 0" class="positiv-value"> ( +{{ machineSub }})</span>
                                                <span *ngIf="machineSub < 0" class="negativ-value"> ( {{ machineSub }})</span>
                                            </p>
                                        </ng-template>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="expanded-panel">
            <mat-expansion-panel-header>
                <mat-panel-title> Stats journalière</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="datepicker">
                Date : 
                <select (change)="changeDateChoice($event)" class="date-selection">
                    <option *ngFor="let date of dateChoices" [value]="date">
                        {{ 'GENERAL.DATE.'+date | translate }}
                    </option>
                </select>
                <mat-form-field *ngIf="chosenDate === 'CUSTOM'">
                    <mat-label>{{ 'GENERAL.DATEPICKER.SELECT_DATE' | translate }}</mat-label>
                    <mat-date-range-input [rangePicker]="rangePicker">
                        <input matStartDate placeholder="Start date" [formControl]="startDate">
                        <input matEndDate placeholder="End date" [formControl]="endDate">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #rangePicker>
                        <mat-date-range-picker-actions>
                            <button mat-button matDateRangePickerCancel>{{ 'GENERAL.DATEPICKER.CANCEL' | translate }}</button>
                            <button mat-raised-button color="primary" matDateRangePickerApply (click)="changeDate()">{{ 'GENERAL.DATEPICKER.APPLY' | translate }}</button>
                        </mat-date-range-picker-actions>
                    </mat-date-range-picker>
                </mat-form-field>
            </div>
            <div class="sales">
                <div class="sales__list">
                    <h3 class="title">Total par niveau</h3>
                    <div class="table-container2">
                        <table mat-table [dataSource]="totalLevels">
                            <ng-container matColumnDef="level" class="table-header">
                                <th mat-header-cell *matHeaderCellDef>Niveau</th>
                                <td mat-cell *matCellDef="let element">{{ formatLevel(element.level) }}</td>
                                <td class="table-footer" mat-footer-cell *matFooterCellDef>Total</td>
                            </ng-container>
                            <ng-container matColumnDef="totalDose">
                                <th mat-header-cell *matHeaderCellDef>Total Dose</th>
                                <td mat-cell *matCellDef="let element" class="m-4">{{ element.totalDose }}</td>
                                <td class="table-footer" mat-footer-cell *matFooterCellDef>{{ sumLevelDose(totalLevels) }}</td>
                            </ng-container>
                            <ng-container matColumnDef="priceTotal">
                                <th mat-header-cell *matHeaderCellDef>Prix Total</th>
                                <td mat-cell *matCellDef="let element" class="m-4">{{ formatPrice(element.totalPrice) }}</td>
                                <td class="table-footer" mat-footer-cell *matFooterCellDef>{{ formatPrice(sumPrice(totalLevels)) }}</td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>
                            <tr mat-footer-row *matFooterRowDef="displayedColumns2"></tr>
                        </table>
                    </div>
                </div>
                <div class="sales__total">
                    <h3 class="title">Total par produit</h3>
                    <div class="table-container2">
                        <table mat-table [dataSource]="totalProducts">
                            <ng-container matColumnDef="product" class="table-header">
                                <th mat-header-cell *matHeaderCellDef>Produit</th>
                                <td mat-cell *matCellDef="let element">{{ formatProductName(element.product) }}</td>
                                <td class="table-footer" mat-footer-cell *matFooterCellDef>Total</td>
                            </ng-container>
                            <ng-container matColumnDef="totalDose">
                                <th mat-header-cell *matHeaderCellDef>Total Dose</th>
                                <td mat-cell *matCellDef="let element" class="m-4">{{ element.totalDose }}
                                    <span *ngIf="element.totalOption > 0">( +{{ element.totalOption }} en option)</span>
                                    <span *ngIf="element.totalBoost > 0">(dont {{ element.totalBoost }} boost)</span>
                                </td>
                                <td class="table-footer" mat-footer-cell *matFooterCellDef>{{ sumProductDose(totalProducts) }}</td>
                            </ng-container>
                            <ng-container matColumnDef="priceTotal">
                                <th mat-header-cell *matHeaderCellDef>Prix Total</th>
                                <td mat-cell *matCellDef="let element" class="m-4">{{ formatPrice(element.totalPrice) }}</td>
                                <td class="table-footer" mat-footer-cell *matFooterCellDef>{{ formatPrice(sumPrice(totalProducts)) }}</td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns1; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
                            <tr mat-footer-row *matFooterRowDef="displayedColumns1"></tr>
                        </table>
                    </div>
                </div>
            </div>
            <div>
                <h3 class="title">Dose vendu par produit</h3>
                <div class="table-container2">
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th *ngFor="let product of products" >{{ formatProductName(product) }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let machine of activeMachines">
                                <td>{{ machine.label }}</td>
                                <td *ngFor="let product of products" >{{ getSalesByMachineAndProduct(machine, product) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
    
</div>

