import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { Machine } from 'src/app/modules/administration/models/machine.model';
import { MachineService } from '../../../../service/machine.service';
import { ProductSale } from 'src/app/modules/administration/models/product-sales.model';
import { StockService } from 'src/app/modules/administration/service/stock.service';
import { Stock } from 'src/app/modules/administration/models/stock.model';
import { MachineConfig } from 'src/app/modules/administration/models/config.model';

@Component({
  selector: 'app-machine-list',
  templateUrl: './machine-list.component.html',
  styleUrls: ['./machine-list.component.scss']
})
export class MachineListComponent implements OnInit {

    public activeMachines: Machine[] = [];
    public inactiveMachines: Machine[] = [];
    public stocks: Stock[] = [];
    public configs: MachineConfig[] = [];
    public sales: ProductSale[] = [];
    public complements: ProductSale[] = [];
    public waters: ProductSale[] = [];
    public boosts: ProductSale[] = [];
    public user: any;
    public startDate: FormControl = new FormControl(new Date());
    public endDate: FormControl = new FormControl(new Date());
    public dateChoices: string[] = [
        "TODAY", "THIS_WEEK", "THIS_MONTH", "LAST_MONTH", "CUSTOM"
    ];
    public chosenDate: string = "TODAY";

    constructor(private readonly machineService: MachineService, private readonly stockService: StockService, private readonly _router: Router, private readonly storage: StorageService) {
    }

    ngOnInit() {
        this.user = this.storage.get('user');
        this.stockService.getAllStock().subscribe({
            next: (stocks) => {
                this.stocks = stocks;
            },
            error: (e) => console.error(e)
        });
        this.machineService.getAllConfigs().subscribe({
            next: (configs) => {
                this.configs = configs;
            },
            error: (e) => console.error(e)
        });
        this.machineService.getMachines(this.isAdmin() ? null : this.user.machineCode).subscribe({
            next: (machines) => {
                this.activeMachines = machines.filter(machine => machine.actif).sort((a, b) => a.label.localeCompare(b.label));
                this.inactiveMachines = machines.filter(machine => !machine.actif).sort((a, b) => a.label.localeCompare(b.label));
            },
            error: (e) => console.error(e)
        });
        this.loadMachineSales();
    }

    public addBuilding (): void {
        this._router.navigate(
            ['admin/building/new']
        );
    }

    public addMachine (): void {
        this._router.navigate(
            ['admin/machine/new']
        );
    }

    public selectMachine (machine: Machine): void {
        this._router.navigate(
            ['admin/machine', machine.identifier]
        );
    }

    public isStatusOk (machine: Machine) : boolean {
        return machine.statusCode === "OK";
    }

    public isStockLow (machine: Machine) : boolean {
        const machineLowStock = this.stocks.filter(
            stock => stock.buildingId == machine.buildingId && stock.dose == 0
        );
        return machineLowStock.length > 0;
    }

    public isBacEmpty (machine: Machine) : boolean {
        const machineEmptyBac = this.configs.filter(
            config => config.machineId == machine.id && config.product !== '' && config.dose == 0
        );
        return machineEmptyBac.length > 0;
    }

    public isAdmin (): boolean {
        return this.user?.status == 'ADMIN' || this.user?.status == 'SUPERADMIN';
    }

    public isSuperAdmin (): boolean {
        return this.user?.status == 'SUPERADMIN';
    }

    public changeDateChoice (event: any) {
        var date = event.target.value;
        this.chosenDate = date;
        if (date === 'CUSTOM') {

        } else {
            var currentDate = new Date();
            switch (date) {
                case 'TODAY':
                    this.startDate.value.setDate(currentDate.getDate());
                    this.endDate.value.setDate(currentDate.getDate());
                    break;
                case 'THIS_WEEK':
                    var firstDay = this.getFirstDayOfWeek(currentDate);
                    var lastDay = this.getLastDayOfWeek(currentDate);
                    this.startDate.value.setDate(firstDay.getDate());
                    this.startDate.value.setFullYear(firstDay.getFullYear());
                    this.startDate.value.setMonth(firstDay.getMonth());
                    this.endDate.value.setDate(lastDay.getDate());
                    this.endDate.value.setFullYear(lastDay.getFullYear());
                    this.endDate.value.setMonth(lastDay.getMonth());
                    break;
                case 'THIS_MONTH':
                    var firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                    var lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                    this.startDate.value.setDate(firstDay.getDate());
                    this.startDate.value.setFullYear(firstDay.getFullYear());
                    this.startDate.value.setMonth(firstDay.getMonth());
                    this.endDate.value.setDate(lastDay.getDate());
                    this.endDate.value.setFullYear(lastDay.getFullYear());
                    this.endDate.value.setMonth(lastDay.getMonth());
                    break;
                case 'LAST_MONTH':
                    var year = currentDate.getMonth() == 0 ? currentDate.getFullYear()-1 : currentDate.getFullYear();
                    var month = currentDate.getMonth() == 0 ? 11 : currentDate.getMonth()-1;
                    var firstDay = new Date(year, month, 1);
                    var lastDay = new Date(year, month+1, 0);
                    this.startDate.value.setFullYear(firstDay.getFullYear());
                    this.startDate.value.setMonth(firstDay.getMonth());
                    this.startDate.value.setDate(firstDay.getDate());
                    this.endDate.value.setFullYear(lastDay.getFullYear());
                    this.endDate.value.setMonth(lastDay.getMonth());
                    this.endDate.value.setDate(lastDay.getDate());
                    break;
                default:
                    this.startDate.value.setDate(currentDate.getDate());
                    this.endDate.value.setDate(currentDate.getDate());
                    break;
                    
            }
            this.loadMachineSales();
        }
        
    }

    public changeDate () {
        this.startDate.value.setDate(this.startDate.value.getDate() +1);
        this.endDate.value.setDate(this.endDate.value.getDate() +1);
        this.loadMachineSales();
    }
    
    private loadMachineSales () {
        this.machineService.getAllMachineSales(this.startDate.value, this.endDate.value, this.isAdmin() ? null : this.user.machineCode).subscribe({
            next: (sales) => {
                this.sales = sales.filter(
                    sale => sale.product !== 'WATER'
                );
                this.complements = sales.filter(
                    sale => sale.complement === 'CREATINE'
                );
                this.boosts = sales.filter(
                    sale => sale.boost
                );
                this.waters = sales.filter(
                    sale => sale.product === 'WATER'
                );
            },
            error: (e) => console.error(e)
        });
    }

    private getFirstDayOfWeek(d: Date) {
        d = new Date(d);
        var day = d.getDay(),
          diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    private getLastDayOfWeek(d: Date) {
        d = new Date(d);
        var day = d.getDay(),
        diff = d.getDate() - day + 7; // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    public getTotalPrice () {
        var price = this.sales.reduce((acc, obj) => {
                return acc + obj.price;
            }, 0
        );
        var priceInEuro = price / 100; 
        return priceInEuro.toFixed(2) + ' €';
    }

    public getSaleByMachine (machineId: number) {
        return this.sales.filter(sale => sale.machineId == machineId).length;
    }

    public getComplementByMachine (machineId: number) {
        return this.complements.filter(sale => sale.machineId == machineId).length;
    }

    public getBoostByMachine (machineId: number) {
        return this.boosts.filter(sale => sale.machineId == machineId).length;
    }

    public getWaterByMachine (machineId: number) {
        return this.waters.filter(sale => sale.machineId == machineId).length;
    }

    public getTotalPriceByMachine (machineId: number) {
        var price = this.sales.filter(sale => sale.machineId == machineId)
            .reduce((acc, obj) => {
                return acc + obj.price;
            }, 0
        );
        var priceInEuro = price / 100; 
        return priceInEuro.toFixed(2) + ' €';
    }

}
