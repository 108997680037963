import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

    protected URL: string = environment.backendApi;
    constructor(private httpClient: HttpClient, private storage: StorageService) { }

    public getToken(){
      let data = this.storage.get('user');
      return data;
     }

    public updatePassword(passwordObject: any): Observable<any> {
        let loginData = this.getToken();
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loginData.token}`
            })
        };
        return this.httpClient.post<any>(this.URL+'/users/update-password', passwordObject, httpOptions);
    }

    public getUsers(): Observable<User[]> {
        let loginData = this.getToken();
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loginData.token}`
            })
        };
        return this.httpClient.get<User[]>(`${this.URL}/users`, httpOptions);
    }

    public getUserById(userId: string): Observable<User> {
        let loginData = this.getToken();
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loginData.token}`
            })
        };
        return this.httpClient.get<User>(`${this.URL}/user/${userId}`, httpOptions);
    }

    public createUser(user: any): Observable<any> {
        let loginData = this.getToken();
        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/x-www-form-urlencoded',
                'enctype': 'multipart/form-data',
                'Authorization': `Bearer ${loginData.token}`
            })
        };
        return this.httpClient.post<any>(this.URL+'/user/create', user, httpOptions);
    }

}
