import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../service/user.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

    public user: any;
    
    private id: string|null = null;

    constructor(private userService: UserService, private _Activatedroute:ActivatedRoute
    ) {
    }

    ngOnInit() {
        this._Activatedroute.paramMap.subscribe(paramMap => { 
            this.id = paramMap.get('id');
            if (this.id !== null) {
                this.userService.getUserById(this.id).subscribe({
                    next: (user) => {
                        this.user = user;
                    },
                    error: (e) => console.error(e)
                });
            }
        });
        
    }
}

