<ng-container *ngIf="data.type === 'ticket'">

    <h2 mat-dialog-title>{{ getTitle() }}</h2>
    <div mat-dialog-content style="max-width: 800px" class="w-full p-4">
        <ng-container *ngIf="action === 'View' || action === 'Update'">
            <div class="w-full lg:w-1/1">
                <div class="m-2 p-2 flex row">
                    <p class="text-2xl font-bold">Salle :</p>
                    <p class="text-2xl ml-2">{{local_data.label}}</p>
                </div>
                <div class="m-2 p-2 flex row">
                    <p class="text-2xl font-bold">Date de création:</p>
                    <p class="text-2xl ml-2">{{local_data.created_date | date:'short'}}</p>
                </div>
                <div class="m-2 p-2">
                    <p class="text-2xl font-bold">Titre:</p>
                    <p class="text-2xl ml-2">{{local_data.title}}</p>
                </div>
                <div class="m-2 p-2">
                    <p class="text-2xl font-bold">Description:</p>
                    <p class="text-2xl ml-2">{{local_data.description}}</p>
                </div>
                <div class="preview"
                    *ngIf="local_data.image && local_data.image !== null">
                    <img [src]="local_data.image" alt="ticket image" />
                </div>
                <div class="w-full m-2">
                    <label class="ml-2 mb-2 text-2xl font-semibold">Status : </label>
                    <select [formControl]="ticketStatus" class="w-1/2" *ngIf="action === 'Update'; else elseTemplate">
                        <option *ngFor="let status of ticketStatuses" [value]="status">{{formatStatus(status) }}</option>
                    </select>
                    <ng-template #elseTemplate>
                        <p class="ml-2">{{ formatStatus(ticketStatus.value) }}</p>
                    </ng-template>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="action === 'Add'">
            <h3 *ngIf="!isAdmin()">Salle: {{machineData.label}}</h3>
            <div class="client-filter" *ngIf="isAdmin()">
                <select (change)="selectMachine($event)">
                    <option *ngFor="let machine of machines" [value]="machine.id">
                        {{ machine.label }}
                    </option>
                </select>
            </div>
            <form [formGroup]="ticketForm" class="p-4">
                <div class="w-full m-2">
                    <label class="ml-4 text-2xl	font-semibold">Catégorie <span class="required">*</span> : </label>
                    <select>
                        <option *ngFor="let categorie of categories">
                            {{ categorie }}
                        </option>
                    </select>
                </div>
                <div class="w-full m-2">
                    <label class="ml-4 text-2xl	font-semibold">Titre <span class="required">*</span></label>
                    <input type="text" class="w-full" placeholder="Titre" formControlName="title">
                </div>
                <div class="w-full m-2">
                    <label class="ml-4 text-2xl	font-semibold">Description <span class="required">*</span></label>
                    <textarea type="text" class="w-full" placeholder="Description" formControlName="description"></textarea>
                </div>
                <div class="mb-4 m-2">
                    <label for="image"
                        class=" ml-4 block text-gray-700 font-semibold mb-2">Image</label>

                    <div class="preview mb-4" *ngIf="preview && preview !== null">
                        <img [src]="preview" [alt]="ticketForm.value.name" />
                    </div>
                    <input type="file" id="image" name="image"
                        (change)="onImageChange($event)"
                        class="w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                </div>
            </form>
        </ng-container>
    </div>

</ng-container>
<ng-container *ngIf="data.type === 'command'">
    <h2 mat-dialog-title>{{ getTitle() }}</h2>
    <div mat-dialog-content style="max-width: 800px;"
        class="w-full p-4 overflow-hidden">
        <ng-container *ngIf="action === 'View' || action === 'Update'">
            <div class="w-full lg:w-1/1">
                <div class="m-2 p-2 flex row">
                    <p class="text-2xl font-bold">{{ 'MODULES.ADMINISTRATION.COMMAND.COMMAND_NUMBER' | translate }} :</p>
                    <p class="text-2xl ml-2">{{local_data.commandNumber}}</p>
                </div>
                <div class="m-2 p-2 flex row">
                    <table class="w-full" mat-table [dataSource]="local_data.products">
                        <ng-container matColumnDef="code">
                            <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.COMMAND.PRODUCT_CODE' | translate }}</th>
                            <td mat-cell *matCellDef="let element">
                                {{ formatProductName(element.code) }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="quantity">
                            <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.COMMAND.QUANTITY' | translate }}</th>
                            <td mat-cell *matCellDef="let element">
                                {{element.quantity}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="price">
                            <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.COMMAND.PRICE' | translate }}</th>
                            <td mat-cell *matCellDef="let element">
                                {{ formatPrice(element.price) }}
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>
                </div>

                <div class="m-2 p-2 flex row">
                    <p class="text-2xl font-bold">{{ 'MODULES.ADMINISTRATION.COMMAND.BUILDING_NAME' | translate }} :</p>
                    <p class="text-2xl ml-2">{{local_data.name}}</p>
                </div>
                <div class="w-full m-2">
                    <label class="ml-2 mb-2 text-2xl font-semibold">{{ 'MODULES.ADMINISTRATION.COMMAND.STATUS' | translate }} : </label>
                    <select [formControl]="commandStatus" class="w-1/2" *ngIf="action === 'Update'; else elseTemplate">
                        <option *ngFor="let status of commandStatuses" [value]="status">{{ formatStatus(status) }}</option>
                    </select>
                    <ng-template #elseTemplate>
                        <p class="ml-2">{{ formatStatus(commandStatus.value) }}</p>
                    </ng-template>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="action === 'Add'">
            <form [formGroup]="commandForm" class="pt-4 pb-4">
                <div class="mb-4 flex row" *ngIf="isAdmin()">
                    <div class="w-1/2 m-2">
                        <label class="ml-4 text-2xl	font-semibold">{{ 'MODULES.ADMINISTRATION.COMMAND.COMMAND_NUMBER' | translate }}</label>
                        <input type="text" class="w-full" placeholder="{{ 'MODULES.ADMINISTRATION.COMMAND.COMMAND_PLACEHOLDER' | translate }}" formControlName="commandNumber">
                    </div>
                    <div class="w-1/2 m-2">
                        <label class="ml-4 text-2xl	font-semibold">{{ 'MODULES.ADMINISTRATION.COMMAND.BUILDING_NAME' | translate }}</label>
                        <select formControlName="buildingID" required class="w-full">
                            <option *ngFor="let building of buildingList" [value]="building['id']">
                                {{building['name']}} - {{building['code']}}
                            </option>
                            </select>
                            </div>
                </div>
                <div formArrayName="products">
                    <div>
                        <div class="mb-4 flex row">
                            <div class="w-5/12 m-1">
                                <label class="ml-4 text-2xl	font-semibold">{{ 'MODULES.ADMINISTRATION.COMMAND.PRODUCT_CODE' | translate }}</label>
                            </div>
                            <div class="w-3/12 m-1">
                                <label class="ml-4 text-2xl	font-semibold">{{ 'MODULES.ADMINISTRATION.COMMAND.QUANTITY' | translate }}</label>
                            </div>
                            <div class="w-3/12 m-1">
                                <label class="ml-4 text-2xl	font-semibold">{{ 'MODULES.ADMINISTRATION.COMMAND.PRICE' | translate }}</label>
                            </div>
                        </div>
                    </div>
                    <div
                        *ngFor="let productField of productFormControls; let i=index"
                        [formGroupName]="i"
                        class="product-item">
                        <div class="mb-4 flex row">
                            <div class="w-5/12 m-1">
                                <div>
                                    <img [src]="getimageFromProduct(productField.value['code'])" class="product-image" alt="machine">
                                    <label>{{ formatProductName(productField.value['code']) }}</label>
                                </div>
                            </div>
                            <div class="w-3/12 m-1">
                                <div>
                                    <button (click)="decreaseQuantity(i)" class="btn btn-default product-button product-button__left">-</button>
                                    <label>{{ formatProductName(productField.value['quantity']) }}</label>
                                    <button (click)="increaseQuantity(i)" class="btn btn-default product-button product-button__right">+</button>
                                </div>
                            </div>
                            <div class="w-3/12 m-1">
                                <p>{{ showProductPrice(i) }}</p>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
            </form>
        </ng-container>
    </div>
</ng-container>
<ng-container class="p-4" *ngIf="action === 'Delete'">
    Voulez-vous supprimer le ticket : <span class="w-full">{{local_data.title}}</span>?
</ng-container>
<div class="product-total" *ngIf="data.type === 'command'">
    Total HT : {{ formatPrice(showProductTotal(false)) }}
</div>
<div class="product-total" *ngIf="data.type === 'command'">
    TVA à 5,5 %
</div>
<div class="product-total" *ngIf="data.type === 'command'">
    Total TTC : {{ formatPrice(showProductTotal(true)) }}
</div>
<div mat-dialog-actions class="p-4">
    <button mat-stroked-button (click)="doAction()" class="action-button" color="primary" *ngIf="action !== 'View'">
        {{ 'MODULES.ADMINISTRATION.COMMAND.FORM.ADD' | translate }}
    </button>
    <button mat-stroked-button color="warn" class="cancel-button" (click)="closeDialog()">
        {{ 'MODULES.ADMINISTRATION.COMMAND.FORM.CANCEL' | translate }}
    </button>
</div>