<!-- table -->
<div>
    <div class="flex row justify-around p-4 m-2">
        <div class="auto">
            <mat-card class="shadow-none">
                <div class="p-30 bg-light-primary text-center cursor-pointer rounded" (click)="btnCategoryClick('')">
                    <h3 class="text-2xl	  font-semibold	m-0 mb-4">{{ totalCount }}</h3>
                    <h6 class=" font-semibold m-0 text-xl">{{ 'MODULES.ADMINISTRATION.TICKET.TOTAL' | translate }}</h6>
                </div>
            </mat-card>
        </div>
        <div class="auto">
            <mat-card class="shadow-none">
                <div class="p-30 bg-light-warning text-center cursor-pointer rounded"
                    (click)="btnCategoryClick('Unprocessed')">
                    <h3 class="text-2xl	  font-semibold m-0 mb-4">{{ unprocessed }}</h3>
                    <h6 class=" font-semibold m-0 text-xl">{{ 'MODULES.ADMINISTRATION.TICKET.UNPROCESS' | translate }}</h6>
                </div>
            </mat-card>
        </div>
        <div class="auto">
            <mat-card class="shadow-none">
                <div class="p-30 bg-light-success text-center cursor-pointer rounded"
                    (click)="btnCategoryClick('In process')">
                    <h3 class="text-2xl	  font-semibold m-0 mb-4">{{ inProcess }}</h3>
                    <h6 class=" font-semibold m-0 text-xl">{{ 'MODULES.ADMINISTRATION.TICKET.IN_PROGRESS' | translate }}</h6>
                </div>
            </mat-card>
        </div>
        <div class="auto">
            <mat-card class="shadow-none">
                <div class="p-30 bg-light-error text-center cursor-pointer rounded"
                    (click)="btnCategoryClick('Already processed')">
                    <h3 class="text-2xl	  font-semibold m-0 mb-4">{{ alreadyProcessed }}</h3>
                    <h6 class=" font-semibold m-0 text-xl">{{ 'MODULES.ADMINISTRATION.TICKET.FINISH' | translate }}</h6>
                </div>
            </mat-card>
        </div>
    </div>
    <mat-card *ngIf="dataSource.data" class="table-container shadow-none">
        <table mat-table [dataSource]="dataSource.data">
            <ng-container matColumnDef="id" class="table-header">
                <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.TICKET.HEADER.ID' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.id }}</td>
            </ng-container>
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.TICKET.HEADER.TITLE' | translate }}</th>
                <td mat-cell *matCellDef="let element" class="m-4">{{ element.title | slice:0:24 }}</td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.TICKET.HEADER.STATUS' | translate }}</th>
                <td mat-cell *matCellDef="let element" class="justify-center" class="m-4">
                    <div [ngClass]="element.status" class="inline-flex  text-white justify-center rounded px-2 py-1 status_button">
                        <span class="text-lg  font-semibold">{{ formatStatus(element.status) | uppercase }}</span>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="machine">
                <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.TICKET.HEADER.MACHINE' | translate }}</th>
                <td mat-cell *matCellDef="let element" class="m-4">{{ element.label | slice:0:24 }}</td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>{{ 'MODULES.ADMINISTRATION.TICKET.HEADER.DATE' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.created_date | date:'short' }}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="custom-action-header flex flex-row justify-center">
                    <span class="mt-4">{{ 'MODULES.ADMINISTRATION.TICKET.HEADER.ACTION' | translate }}</span>
                    <div class="mt-2 ml-8">
                        <button mat-flat-button matTooltip="Ajouter un Ticket" color="primary" (click)="openDialog('Add')">
                            <mat-icon>add</mat-icon>
                            Ajouter un ticket
                        </button>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-flat-button color="primary" (click)="openDialog('View', element)"><mat-icon>visibility</mat-icon></button>
                    <button mat-flat-button class="bg-amber-500 text-white"
                        *ngIf="isAdmin() && element.status !== 'Already processed'"
                        (click)="openDialog('Update', element)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-flat-button color="warn" *ngIf="element.status !== 'Already processed'"
                        (click)="openDialog('Delete', element)">
                        <mat-icon>delete_outline</mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </mat-card>
</div>