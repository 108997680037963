import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StorageService } from 'src/app/services/storage.service';
import { Machine } from 'src/app/modules/administration/models/machine.model';
import { MachineService } from '../../service/machine.service';
import { ProductSale, ProductSaleTotal, LevelSaleTotal, WaterSaleTotal } from 'src/app/modules/administration/models/product-sales.model';
import { StatService } from '../../service/stat.service';
import { Stat, ProductStat } from '../../models/stat.model';

@Component({
  selector: 'app-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.scss']
})
export class StatComponent implements OnInit {

  public activeMachines: Machine[] = [];
  public stat: Stat | undefined;
  public productStats: ProductStat[] = [];
  public sales: ProductSale[] = [];
  public complements: ProductSale[] = [];
  public waters: ProductSale[] = [];
  public boosts: ProductSale[] = [];
  public totalProducts: ProductSaleTotal[] = [];
  public totalLevels: LevelSaleTotal[] = [];
  public user: any;
  public startDate: FormControl = new FormControl(new Date());
  public endDate: FormControl = new FormControl(new Date());
  public products: string[] = [];
  public dateChoices: string[] = [
      "TODAY", "THIS_WEEK", "THIS_MONTH", "LAST_MONTH", "CUSTOM"
  ];
  public chosenDate: string = "TODAY";
  public months: number[] = [
      1, 2, 3, 4, 5, 6, 7, 8, 9
  ];


  displayedColumns1: string[] = [
    'product',
    'totalDose',
    'priceTotal'
  ];

  displayedColumns2: string[] = [
    'level',
    'totalDose',
    'priceTotal'
  ];

  constructor(private machineService: MachineService, private statService: StatService, private storage: StorageService) {
  }

  ngOnInit() {
      this.user = this.storage.get('user');
      this.machineService.getMachines(this.isAdmin() ? null : this.user.machineCode).subscribe({
          next: (machines) => {
              this.statService.getMonthlyStats().subscribe({
                next: (monthlyStats) => {
                    machines.forEach(machine => {
                    machine.stats = monthlyStats.filter(stat => stat.machineId == machine.id).sort((a, b) => a.month - b.month);
                  });
                  if (this.isSuperAdmin()) {
                    this.activeMachines = machines.sort((a, b) => a.label.localeCompare(b.label));
                  } else {
                    this.activeMachines = machines.filter(machine => machine.actif).sort((a, b) => a.label.localeCompare(b.label));
                  }

                },
                error: (e) => console.error(e)
            });
          },
          error: (e) => console.error(e)
      });
      this.statService.getStats().subscribe({
          next: (stats) => {
              this.stat = stats[0];
          },
          error: (e) => console.error(e)
      });
      this.statService.getProductStats().subscribe({
          next: (productStats) => {
              this.productStats = productStats.sort((a, b) => b.totalDose - a.totalDose);
          },
          error: (e) => console.error(e)
      });
      
      this.loadMachineSales();
  }

  public isAdmin (): boolean {
      return this.user?.status == 'ADMIN';
  }

  public isSuperAdmin (): boolean {
      return this.user?.status == 'SUPERADMIN';
  }

  public changeDateChoice (event: any) {
      var date = event.target.value;
      this.chosenDate = date;
      if (date === 'CUSTOM') {

      } else {
          var currentDate = new Date();
          switch (date) {
              case 'TODAY':
                  this.startDate.setValue(currentDate);
                  this.endDate.setValue(currentDate);
                  break;
              case 'THIS_WEEK':
                  var firstDay = this.getFirstDayOfWeek(currentDate);
                  var lastDay = this.getLastDayOfWeek(currentDate);
                  this.startDate.setValue(firstDay);
                  this.endDate.setValue(lastDay);
                  break;
              case 'THIS_MONTH':
                  var firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                  var lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                  firstDay.setDate(firstDay.getDate() + 1);
                  this.startDate.setValue(firstDay);
                  lastDay.setDate(lastDay.getDate() + 1);
                  this.endDate.setValue(lastDay);
                  break;
              case 'LAST_MONTH':
                  var year = currentDate.getMonth() == 0 ? currentDate.getFullYear()-1 : currentDate.getFullYear();
                  var month = currentDate.getMonth() == 0 ? 11 : currentDate.getMonth()-1;
                  var firstDay = new Date(year, month, 1);
                  var lastDay = new Date(year, month+1, 0);
                  firstDay.setDate(firstDay.getDate() + 1);
                  this.startDate.setValue(firstDay);
                  lastDay.setDate(lastDay.getDate() + 1);
                  this.endDate.setValue(lastDay);
                  break;
              default:
                  this.startDate.value.setDate(currentDate.getDate());
                  this.endDate.value.setDate(currentDate.getDate());
                  break;
                  
          }
          this.loadMachineSales();
      }
      
  }

  public changeDate () {
      this.startDate.value.setDate(this.startDate.value.getDate() +1);
      this.endDate.value.setDate(this.endDate.value.getDate() +1);
      this.loadMachineSales();
  }
  
  private loadMachineSales () {
      this.machineService.getAllMachineSales(this.startDate.value, this.endDate.value, this.isAdmin() ? null : this.user.machineCode).subscribe({
          next: (sales) => {
              this.sales = sales.filter(
                  sale => sale.product !== 'WATER'
              );
              this.complements = sales.filter(
                  sale => sale.complement === 'CREATINE'
              );
              this.boosts = sales.filter(
                  sale => sale.boost
              );
              this.waters = sales.filter(
                  sale => sale.product === 'WATER'
              );
                this.products = [...new Set(sales.map(item => item.taste == '' ? item.product : item.product + '_' + item.taste))];
                if (this.products.indexOf('CREATINE') === -1 && sales.filter(sale => (sale.complement == 'CREATINE')).length > 0) {
                  this.products.push('CREATINE');
                }
                if (this.products.indexOf('WATER') !== -1) {
                  this.products.splice(this.products.indexOf('WATER'), 1);;
                }
                this.products = this.products.sort((a, b) => a.localeCompare(b));
                this.totalProducts = [];
                this.products.forEach(product => {
                  this.totalProducts.push({
                      product: product,
                      totalDose: sales.filter(sale => ((sale.taste == '' ? sale.product : sale.product + '_' + sale.taste) == product)).length,
                      totalOption: sales.filter(sale => (sale.complement == product)).length,
                      totalBoost: sales.filter(sale => ((sale.product + '_' + sale.taste) == product && sale.boost)).length,
                      totalPrice: sales.filter(
                              sale => ((sale.taste == '' ? sale.product : sale.product + '_' + sale.taste) == product)
                          ).reduce((acc, obj) => {
                                  return acc + obj.price;
                              }, 0
                          )
                  });
                });
                this.totalProducts.sort((a, b) => b.totalDose - a.totalDose);
                const levels = [...new Set(sales.map(item => item.level))];
                this.totalLevels = [];
                levels.forEach(level => {
                    if (level !== 'LEVEL2') {
                      if (level === 'LEVEL1') {
                        this.totalLevels.push({
                          level: level,
                          totalDose: sales.filter(sale => (sale.level == 'LEVEL1' || sale.level == 'LEVEL2') && sale.product !== 'WATER').length 
                          + sales.filter(sale => (sale.level == 'LEVEL1' || sale.level == 'LEVEL2') && sale.complement === 'CREATINE').length 
                          + (sales.filter(sale => (sale.level == 'LEVEL1' || sale.level == 'LEVEL2') && sale.boost).length / 2),
                          totalCb: sales.filter(sale => (sale.level == 'LEVEL1' || sale.level == 'LEVEL2') && sale.product !== 'WATER' && !sale.clientId && sale.price > 0).length,
                          totalPrice: sales.filter(sale => (sale.level == 'LEVEL1' || sale.level == 'LEVEL2') && sale.product !== 'WATER').reduce((acc, obj) => {
                                  return acc + obj.price;
                              }, 0
                          )
                        });
                      } else {
                        this.totalLevels.push({
                          level: level,
                          totalDose: sales.filter(sale => sale.level == level && sale.product !== 'WATER').length 
                          + sales.filter(sale => sale.level == level && sale.complement === 'CREATINE').length 
                          + (sales.filter(sale => sale.level == level && sale.boost).length / 2),
                          totalCb: sales.filter(sale => sale.level == level && sale.product !== 'WATER' && !sale.clientId && sale.price > 0).length,
                          totalPrice: sales.filter(sale => sale.level == level && sale.product !== 'WATER').reduce((acc, obj) => {
                                  return acc + obj.price;
                              }, 0
                          )
                        });
                      }
                    }
                });
                this.totalLevels.sort((a, b) => b.totalDose - a.totalDose);
          },
          error: (e) => console.error(e)
      });
  }

  private getFirstDayOfWeek(d: Date) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
  }

  private getLastDayOfWeek(d: Date) {
      d = new Date(d);
      var day = d.getDay(),
      diff = d.getDate() - day + 7; // adjust when day is sunday
      return new Date(d.setDate(diff));
  }

  public getTotalPrice () {
      var price = this.sales.reduce((acc, obj) => {
              return acc + obj.price;
          }, 0
      );
      var priceInEuro = price / 100; 
      return priceInEuro.toFixed(2) + ' €';
  }

  public getSaleByMachine (machineId: number) {
      return this.sales.filter(sale => sale.machineId == machineId).length;
  }

  public getSalesByMachineAndProduct(machine: Machine, productName: string) {
    return this.sales.filter(
      sale => sale.machineId == machine.id && ((sale.taste == '' ? sale.product : sale.product + '_' + sale.taste) == productName)
    ).length + this.sales.filter(sale => sale.machineId == machine.id && (sale.complement == productName)).length
    + (this.sales.filter(sale => sale.machineId == machine.id && ((sale.taste == '' ? sale.product : sale.product + '_' + sale.taste) == productName) && sale.boost).length / 2)
  }

  public getComplementByMachine (machineId: number) {
      return this.complements.filter(sale => sale.machineId == machineId).length;
  }

  public getBoostByMachine (machineId: number) {
      return this.boosts.filter(sale => sale.machineId == machineId).length;
  }

  public getWaterByMachine (machineId: number) {
      return this.waters.filter(sale => sale.machineId == machineId).length;
  }

  public getTotalPriceByMachine (machineId: number) {
      var price = this.sales.filter(sale => sale.machineId == machineId)
          .reduce((acc, obj) => {
              return acc + obj.price;
          }, 0
      );
      var priceInEuro = price / 100; 
      return priceInEuro.toFixed(2) + ' €';
  }

  public getMachineDosesByMonth(machine: Machine, month: number) {
    let stats = machine.stats.filter(stat => stat.month == month);
    if (stats.length == 0) {
        return 0;
    }
    return stats[0].totalDose;
  }

  public getMachineDosesDiffByMonth(machine: Machine, month: number) {
    let stats = machine.stats.filter(stat => stat.month == month);
    if (stats.length == 0) {
        return 0;
    }
    let lastStats = machine.stats.filter(stat => stat.month == month-1);
    if (lastStats.length == 0) {
        return 0;
    }
    let diff = stats[0].totalDose - lastStats[0].totalDose;
    return diff;
  }

  public getMachinePriceByMonth(machine: Machine, month: number) {
    let stats = machine.stats.filter(stat => stat.month == month);
    if (stats.length == 0) {
        return 0;
    }
    return this.formatPrice(stats[0].totalPrice);
  }

  public getMachinePricesDiffByMonth(machine: Machine, month: number) {
    let stats = machine.stats.filter(stat => stat.month == month);
    if (stats.length == 0) {
        return 0;
    }
    let lastStats = machine.stats.filter(stat => stat.month == month-1);
    if (lastStats.length == 0) {
        return 0;
    }
    let diff = stats[0].totalPrice - lastStats[0].totalPrice;
    return diff;
  }

  public getMachineUserByMonth(machine: Machine, month: number) {
    let stats = machine.stats.filter(stat => stat.month == month);
    if (stats.length == 0) {
        return 0;
    }
    return stats[0].users;
  }

  public getMachineUsersDiffByMonth(machine: Machine, month: number) {
    let stats = machine.stats.filter(stat => stat.month == month);
    if (stats.length == 0) {
        return 0;
    }
    let lastStats = machine.stats.filter(stat => stat.month == month-1);
    if (lastStats.length == 0) {
        return 0;
    }
    let diff = stats[0].users - lastStats[0].users;
    return diff;
  }

  public getMachineSubscriberByMonth(machine: Machine, month: number) {
    let stats = machine.stats.filter(stat => stat.month == month);
    if (stats.length == 0) {
        return 0;
    }
    return stats[0].subscriber;
  }

  public getMachineSubscriberDiffByMonth(machine: Machine, month: number) {
    let stats = machine.stats.filter(stat => stat.month == month);
    if (stats.length == 0) {
        return 0;
    }
    let lastStats = machine.stats.filter(stat => stat.month == month-1);
    if (lastStats.length == 0) {
        return 0;
    }
    let diff = stats[0].subscriber - lastStats[0].subscriber;
    return diff;
  }

  public formatProductName (productName: string) {
      switch (productName) {
          case 'WATER':
              return 'eau';
          case 'CREATINE':
              return 'Créatine';
          case 'PROTEIN_ISOLATE':
              return 'Protéine Isolate';
          case 'PROTEIN_ISOLATE_VANILLA':
              return 'Protéine Isolate vanille';
          case 'PROTEINE_ISOLATE_VANILLE':
              return 'Protéine Isolate vanille';
          case 'PROTEIN_ISOLATE_CHOCOLATE':
              return 'Protéine Isolate chocolat';
          case 'PROTEINE_ISOLATE_CHOCOLAT':
              return 'Protéine Isolate chocolat';
          case 'PROTEIN_ISOLATE_COCO':
              return 'Protéine Isolate coco';
          case 'PROTEINE_ISOLATE_COCO':
              return 'Protéine Isolate coco';
          case 'PROTEIN_ISOLATE_COOKIE':
              return 'Protéine Isolate cookie';
          case 'PROTEINE_ISOLATE_COOKIE':
              return 'Protéine Isolate cookie';
          case 'PROTEIN_ISOLATE_STRAWBERRY':
              return 'Protéine Isolate Fraise';
          case 'PUMP_MIXED_BERRIES':
              return 'PUMP fruits des bois';
          case 'PUMP_FRUIT_PUNCH':
              return 'PUMP fruits punch';
          case 'PUMP_PUNCH_FRUIT':
              return 'PUMP fruits punch';
          case 'PROTEIN':
              return 'Protéine';
          case 'PROTEIN_VANILLA':
              return 'Protéine vanille';
          case 'PROTEIN_CHOCOLATE':
              return 'Protéine chocolat';
          case 'GAINEUR':
              return 'Gaineur';
          case 'GAINEUR_VANILLA':
              return 'Gaineur vanille';
          default:
              return productName;
      }
  }

  public formatProductTaste (productTaste: string) {
      switch (productTaste) {
          case 'VANILLA':
              return 'Vanille';
          case 'CHOCOLATE':
              return 'Chocolat';
          case 'COCO':
              return 'Coco';
          case 'COOKIE':
              return 'Cookie';
          case 'STRAWBERRY':
              return 'Fraise';
          case 'MIXED_BERRIES':
              return 'fruits des bois';
          case 'PUNCH_FRUIT':
              return 'fruits punch';
          default:
              return productTaste;
      }
  }

  public formatLevel (level: string) {
      switch (level) {
          case 'LEVEL0':
              return 'Non abonné';
          case 'LEVEL1':
          case 'LEVEL2':
              return 'Abonné';
          case 'LEVEL3':
              return 'Manager';
          case 'LEVEL4':
              return 'Employé';
          case 'LEVEL5':
              return 'Abonné eau uniquement';
          default:
              return level;
      }
  }

  public formatWater (water: string) {
      switch (water) {
          case '_100CL':
              return '1L';
          default:
              return water.replace('_', '');;
      }
  }

  public formatPrice (price: number) {
      if (!price) {
          return "";
      }
      var priceInEuro = price / 100; 
      return priceInEuro.toFixed(2) + ' €';
  }

  public sumLevelDose (sales: LevelSaleTotal[] | WaterSaleTotal[]) {
      if (sales.length > 0) {
          return  sales.map(a => a.totalDose).reduce(function(a, b)
          {
          return a + b;
          });
      }
      return 0;
  }

  public sumProductDose (sales: ProductSaleTotal[]) {
      if (sales.length > 0) {
          const totalDose =  sales.map(a => a.totalDose).reduce(function(a, b)
          {
          return a + b;
          });
          const totalComplement =  sales.map(a => a.totalOption).reduce(function(a, b)
          {
          return a + b;
          });
          const totalBoost =  sales.map(a => a.totalBoost).reduce(function(a, b)
          {
          return a + b;
          });
          return totalDose + totalComplement + (totalBoost / 2);
      }
      return 0;
  }

  public sumPrice (sales: ProductSaleTotal[] | LevelSaleTotal[]) {
      if (sales.length > 0) {
          return sales.map(a => a.totalPrice).reduce(function(a, b)
          {
          return a + b;
          });
      }
      return 0;
  }

}

