import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { Machine } from 'src/app/modules/administration/models/machine.model';
import { MachineService } from '../../service/machine.service';
import { environment } from 'src/environments/environment';
import { Invoice } from '../../models/invoice.model';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

    public machines: Machine[] = [];
    public user: any;
    private URL: string = environment.backendApi;

    constructor(private machineService: MachineService, private storage: StorageService) {
    }

    ngOnInit() {
        this.user = this.storage.get('user');
        this.machineService.getMachines(this.isAdmin() ? null : this.user.machineCode).subscribe({
            next: (machines) => {
                this.machines = machines.sort((a, b) => a.label.localeCompare(b.label));
                this.machines.forEach(machine => {
                    this.machineService.getInvoiceForMachine(machine.identifier).subscribe({
                        next: (invoices) => {
                            machine.invoices = invoices;
                        },
                        error: (e) => console.error(e)
                    });
                })
            },
            error: (e) => console.error(e)
        });
    }

    public isAdmin (): boolean {
        return this.user?.status == 'ADMIN' || this.user?.status == 'SUPERADMIN';
    }

    public getFullUrl (invoice: Invoice) {
        return this.URL + '/' + invoice.url;
    }

}

