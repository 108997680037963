
  <main id="MainContent" class="content-for-layout focus-none" role="main"
    tabindex="-1">
    <div id="shopify-section-template--19280186999107__image-with-text" class="shopify-section section">
      <link
        href="//www.shakeitprot.fr/cdn/shop/t/2/assets/component-image-with-text.css?v=113100769818070755561689599136"
        rel="stylesheet" type="text/css" media="all" />
      <div
        class="image-with-text image-with-text--overlap page-width isolate collapse-corners section-template--19280186999107__image-with-text-padding scroll-trigger animate--slide-in">
        <div
          class="image-with-text__grid grid grid--gapless grid--1-col grid--2-col-tablet image-with-text__grid--reverse">
          <div
            class="image-with-text__media-item image-with-text__media-item--medium image-with-text__media-item--middle grid__item">
            <div
              class="image-with-text__media image-with-text__media--medium gradient color-background-1 global-media-settings media"><img
                src="//www.shakeitprot.fr/cdn/shop/files/3_TYPES_DE_BOISSONS_FRAICHES_PROTEINEES_16.png?v=1690193662&amp;width=1500"
                alt
                srcset="//www.shakeitprot.fr/cdn/shop/files/3_TYPES_DE_BOISSONS_FRAICHES_PROTEINEES_16.png?v=1690193662&amp;width=165 165w, //www.shakeitprot.fr/cdn/shop/files/3_TYPES_DE_BOISSONS_FRAICHES_PROTEINEES_16.png?v=1690193662&amp;width=360 360w, //www.shakeitprot.fr/cdn/shop/files/3_TYPES_DE_BOISSONS_FRAICHES_PROTEINEES_16.png?v=1690193662&amp;width=535 535w, //www.shakeitprot.fr/cdn/shop/files/3_TYPES_DE_BOISSONS_FRAICHES_PROTEINEES_16.png?v=1690193662&amp;width=750 750w, //www.shakeitprot.fr/cdn/shop/files/3_TYPES_DE_BOISSONS_FRAICHES_PROTEINEES_16.png?v=1690193662&amp;width=1070 1070w, //www.shakeitprot.fr/cdn/shop/files/3_TYPES_DE_BOISSONS_FRAICHES_PROTEINEES_16.png?v=1690193662&amp;width=1500 1500w"
                width="1500" height="844" loading="lazy"
                sizes="(min-width: 1100px) 500px,
              (min-width: 750px) calc((100vw - 130px) / 2), calc((100vw - 50px) / 2)">
            </div>
          </div>
          <div class="image-with-text__text-item grid__item">
            <div
              id="ImageWithText--template--19280186999107__image-with-text"
              class="image-with-text__content image-with-text__content--middle image-with-text__content--desktop-left image-with-text__content--mobile-left image-with-text__content--medium gradient color-background-1 content-container"><h2
                class="image-with-text__heading inline-richtext h1">
                BOOSTEZ VOS RÉSULTATS AVEC SHAKE'IT PROT: le partenaire idéal
                pour des séances gagnantes !
              </h2><a
                href="/collections/all"
                class="button button--primary">
                DÉCOUVRIR
              </a></div>
          </div>
        </div>
      </div>

    </div><div
      id="shopify-section-template--19280186999107__2b955e6c-5d93-436d-a4be-4af2bc5e4d52"
      class="shopify-section section"><link
        href="//www.shakeitprot.fr/cdn/shop/t/2/assets/component-image-with-text.css?v=113100769818070755561689599136"
        rel="stylesheet" type="text/css" media="all" />
      <style data-shopify>.section-template--19280186999107__2b955e6c-5d93-436d-a4be-4af2bc5e4d52-padding {
    padding-top: 27px;
    padding-bottom: 27px;
  }

  @media screen and (min-width: 750px) {
    .section-template--19280186999107__2b955e6c-5d93-436d-a4be-4af2bc5e4d52-padding {
      padding-top: 36px;
      padding-bottom: 36px;
    }
  }</style><div
        class="multirow section-template--19280186999107__2b955e6c-5d93-436d-a4be-4af2bc5e4d52-padding gradient color-background-1">
        <div class="multirow__inner page-width"><div
            class="image-with-text isolate collapse-padding scroll-trigger animate--slide-in">
            <div
              class="image-with-text__grid grid grid--gapless grid--1-col grid--2-col-tablet">
              <div
                class="image-with-text__media-item image-with-text__media-item--medium image-with-text__media-item--middle grid__item">
                <div
                  class="image-with-text__media image-with-text__media--small gradient color-background-1 global-media-settings media"><img
                    src="//www.shakeitprot.fr/cdn/shop/files/photo_shopify_1.png?v=1689603740&amp;width=1500"
                    alt
                    srcset="//www.shakeitprot.fr/cdn/shop/files/photo_shopify_1.png?v=1689603740&amp;width=165 165w, //www.shakeitprot.fr/cdn/shop/files/photo_shopify_1.png?v=1689603740&amp;width=360 360w, //www.shakeitprot.fr/cdn/shop/files/photo_shopify_1.png?v=1689603740&amp;width=535 535w, //www.shakeitprot.fr/cdn/shop/files/photo_shopify_1.png?v=1689603740&amp;width=750 750w, //www.shakeitprot.fr/cdn/shop/files/photo_shopify_1.png?v=1689603740&amp;width=1070 1070w, //www.shakeitprot.fr/cdn/shop/files/photo_shopify_1.png?v=1689603740&amp;width=1500 1500w"
                    width="1500" height="1000" loading="lazy"
                    sizes="(min-width: 1100px) 500px,
                  (min-width: 750px) calc((100vw - 130px) / 2), calc((100vw - 50px) / 2)">
                </div>
              </div>
              <div class="image-with-text__text-item grid__item">
                <div
                  class="image-with-text__content image-with-text__content--middle image-with-text__content--desktop-left image-with-text__content--mobile-left image-with-text__content--small content-container"><p
                    class="image-with-text__text image-with-text__text--caption caption-with-letter-spacing caption-with-letter-spacing--medium">
                    Découvrez
                  </p><h2 class="image-with-text__heading h1 rte">
                    SHAKE'IT PROT
                  </h2><div class="image-with-text__text rte body"><h5>Notre
                      distributeur automatique de protéines est conçu pour
                      simplifier votre parcours fitness en vous fournissant des
                      compléments nutritionnels de qualité supérieure
                      directement dans votre salle de sport.</h5><h5>Scroller
                      pour en savoir plus sur nos produits et commencer votre
                      voyage vers une meilleure nutrition sportive. Prêt à
                      relever le défi?</h5></div></div>
              </div>
            </div>
          </div><div
            class="image-with-text isolate collapse-padding scroll-trigger animate--slide-in">
            <div
              class="image-with-text__grid grid grid--gapless grid--1-col grid--2-col-tablet image-with-text__grid--reverse">
              <div
                class="image-with-text__media-item image-with-text__media-item--medium image-with-text__media-item--middle grid__item">
                <div
                  class="image-with-text__media image-with-text__media--small gradient color-background-1 global-media-settings media"><img
                    src="//www.shakeitprot.fr/cdn/shop/files/Design_sans_titre_7.png?v=1689664885&amp;width=1500"
                    alt
                    srcset="//www.shakeitprot.fr/cdn/shop/files/Design_sans_titre_7.png?v=1689664885&amp;width=165 165w, //www.shakeitprot.fr/cdn/shop/files/Design_sans_titre_7.png?v=1689664885&amp;width=360 360w, //www.shakeitprot.fr/cdn/shop/files/Design_sans_titre_7.png?v=1689664885&amp;width=535 535w, //www.shakeitprot.fr/cdn/shop/files/Design_sans_titre_7.png?v=1689664885&amp;width=750 750w, //www.shakeitprot.fr/cdn/shop/files/Design_sans_titre_7.png?v=1689664885&amp;width=1070 1070w, //www.shakeitprot.fr/cdn/shop/files/Design_sans_titre_7.png?v=1689664885&amp;width=1500 1500w"
                    width="1500" height="857" loading="lazy"
                    sizes="(min-width: 1100px) 500px,
                  (min-width: 750px) calc((100vw - 130px) / 2), calc((100vw - 50px) / 2)">
                </div>
              </div>
              <div class="image-with-text__text-item grid__item">
                <div
                  class="image-with-text__content image-with-text__content--middle image-with-text__content--desktop-left image-with-text__content--mobile-left image-with-text__content--small content-container"><p
                    class="image-with-text__text image-with-text__text--caption caption-with-letter-spacing caption-with-letter-spacing--medium">
                    Shake&#39;it Prot
                  </p><h2 class="image-with-text__heading h1 rte">
                    VOTRE PARTENAIRE DE CONFIANCE EN NUTRITION SPORTIVE
                  </h2><div class="image-with-text__text rte body"><h5>Depuis
                      notre création, Shake'it Prot s'est engagé à fournir des
                      solutions de nutrition sportive de qualité supérieure aux
                      PASSIONNÉS de fitness et aux athlètes AMBITIEUX. Notre
                      histoire a commencé avec trois amis issus du milieu
                      sportif, animés par une vision commune : offrir une
                      expérience de nutrition sportive INNOVANTE et ACCESSIBLE A
                      TOUS. Notre mission est de vous offrir des produits de
                      nutrition sportive de QUALITÉS, conçus pour répondre aux
                      besoins SPÉCIFIQUES de votre corps et de vos OBJECTIFS.
                    </h5><h5>Rejoignez Shake'it Prot dès maintenant et découvrez
                      <br />comment notre expertise et notre passion peuvent
                      vous aider à repousser vos limites. Ensemble, atteignons
                      de nouveaux sommets en matière de nutrition sportive !</h5></div><a
                    href="/pages/contact"
                    class="button button--secondary">
                    Je veux louer ma machine !
                  </a></div>
              </div>
            </div>
          </div><div
            class="image-with-text isolate collapse-padding scroll-trigger animate--slide-in">
            <div
              class="image-with-text__grid grid grid--gapless grid--1-col grid--2-col-tablet">
              <div
                class="image-with-text__media-item image-with-text__media-item--medium image-with-text__media-item--middle grid__item">
                <div
                  class="image-with-text__media image-with-text__media--small gradient color-background-1 global-media-settings media"><img
                    src="//www.shakeitprot.fr/cdn/shop/files/Design_sans_titre_9.png?v=1689665162&amp;width=1500"
                    alt
                    srcset="//www.shakeitprot.fr/cdn/shop/files/Design_sans_titre_9.png?v=1689665162&amp;width=165 165w, //www.shakeitprot.fr/cdn/shop/files/Design_sans_titre_9.png?v=1689665162&amp;width=360 360w, //www.shakeitprot.fr/cdn/shop/files/Design_sans_titre_9.png?v=1689665162&amp;width=535 535w, //www.shakeitprot.fr/cdn/shop/files/Design_sans_titre_9.png?v=1689665162&amp;width=750 750w, //www.shakeitprot.fr/cdn/shop/files/Design_sans_titre_9.png?v=1689665162&amp;width=1070 1070w, //www.shakeitprot.fr/cdn/shop/files/Design_sans_titre_9.png?v=1689665162&amp;width=1500 1500w"
                    width="1500" height="857" loading="lazy"
                    sizes="(min-width: 1100px) 500px,
                  (min-width: 750px) calc((100vw - 130px) / 2), calc((100vw - 50px) / 2)">
                </div>
              </div>
              <div class="image-with-text__text-item grid__item">
                <div
                  class="image-with-text__content image-with-text__content--middle image-with-text__content--desktop-left image-with-text__content--mobile-left image-with-text__content--small content-container"><p
                    class="image-with-text__text image-with-text__text--caption caption-with-letter-spacing caption-with-letter-spacing--medium">
                    Shake&#39;it prot
                  </p><h2 class="image-with-text__heading h1 rte">
                    UNE SIMPLICITÉ À TOUTES ÉPREUVES
                  </h2><div class="image-with-text__text rte body"><h5>Nous
                      pouvons intégré tout logiciel notre distributeur pour
                      AUTOMATISER le processus de paiement et offrir une
                      expérience FLUIDE à vos membres. Ils n'auront qu'à BADGER
                      leur bracelet ou leur carte de membre pour ACCÉDER à nos
                      services. Les paiements seront prélevés en fin de mois, en
                      MÊME TEMPS que leur ABONNEMENT. Nous acceptons également
                      les paiements par CB. </h5><h5>Notre priorité est d'offrir
                      la meilleure EXPÉRIENCE possible à vos membres pendant
                      leurs entraînements. C'est pourquoi nous avons CRÉE un
                      abonnement de 5€. Cet abonnement permet à vos clients de
                      bénéficier de RÉDUCTION sur CHAQUE dose, avec des
                      économies allant jusqu'à 1€/shaker. De plus, ils pourront
                      profiter d'une eau FILTRÉE en illimité et gratuite. Nous
                      croyons fermement que cette SOLUTION d'abonnement
                      renforcera la FIDÉLISATION de vos clients et ajoutera une
                      OPTION supplémentaire à vos offres existantes. Rejoignez
                      nous et OFFFREZ à vos membres une expérience de nutrition
                      sportive ÉCONOMIQUE dès maintenant avec SHAKE'IT PROT !</h5></div><a
                    href="/collections/all"
                    class="button button--secondary">
                    En savoir plus !
                  </a></div>
              </div>
            </div>
          </div></div>
      </div>

    </div><section
      id="shopify-section-template--19280186999107__9dd99ded-89a3-4cc2-b16a-764e481bc524"
      class="shopify-section section"><link
        href="//www.shakeitprot.fr/cdn/shop/t/2/assets/section-image-banner.css?v=81971971763012089891689599137"
        rel="stylesheet" type="text/css" media="all" />
      <link
        href="//www.shakeitprot.fr/cdn/shop/t/2/assets/component-slider.css?v=142503135496229589681689599136"
        rel="stylesheet" type="text/css" media="all" />
      <link
        href="//www.shakeitprot.fr/cdn/shop/t/2/assets/component-slideshow.css?v=83743227411799112781689599136"
        rel="stylesheet" type="text/css" media="all" />

    </section><section
      id="shopify-section-template--19280186999107__45fc4350-6374-44c1-a452-845764995b65"
      class="shopify-section section"><link
        href="//www.shakeitprot.fr/cdn/shop/t/2/assets/section-multicolumn.css?v=120651070842298201681689599137"
        rel="stylesheet" type="text/css" media="all" />
      <link
        href="//www.shakeitprot.fr/cdn/shop/t/2/assets/component-slider.css?v=142503135496229589681689599136"
        rel="stylesheet" type="text/css" media="all" />
      <style data-shopify>.section-template--19280186999107__45fc4350-6374-44c1-a452-845764995b65-padding {
    padding-top: 27px;
    padding-bottom: 27px;
  }

  @media screen and (min-width: 750px) {
    .section-template--19280186999107__45fc4350-6374-44c1-a452-845764995b65-padding {
      padding-top: 36px;
      padding-bottom: 36px;
    }
  }</style><div
        class="multicolumn color-background-1 gradient background-primary">
        <div
          class="page-width section-template--19280186999107__45fc4350-6374-44c1-a452-845764995b65-padding isolate scroll-trigger animate--slide-in"
          data-cascade><div
            class="title-wrapper-with-link title-wrapper--self-padded-mobile title-wrapper--no-top-margin">
            <h2 class="title inline-richtext h2">
              POURQUOI NOUS CHOISIR ?
            </h2></div>
          <div class="center"></div>
        </div>
      </div>

    </section><section
      id="shopify-section-template--19280186999107__9d7abf63-b0ef-41a5-a36f-2b821b06ec62"
      class="shopify-section section"><link
        href="//www.shakeitprot.fr/cdn/shop/t/2/assets/video-section.css?v=163092437706280852751689599137"
        rel="stylesheet" type="text/css" media="all" />
      <link
        href="//www.shakeitprot.fr/cdn/shop/t/2/assets/component-deferred-media.css?v=14096082462203297471689599136"
        rel="stylesheet" type="text/css" media="all" />
      <style data-shopify>.section-template--19280186999107__9d7abf63-b0ef-41a5-a36f-2b821b06ec62-padding {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  @media screen and (min-width: 750px) {
    .section-template--19280186999107__9d7abf63-b0ef-41a5-a36f-2b821b06ec62-padding {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }</style><div class="color-background-1 gradient">
        <div
          class="video-section isolate page-width section-template--19280186999107__9d7abf63-b0ef-41a5-a36f-2b821b06ec62-padding">
          <div><div
              class="title-wrapper title-wrapper--no-top-margin scroll-trigger animate--slide-in">
              <h2 class="title inline-richtext h2">Présentation de notre
                distributeur !</h2>
            </div></div>
          <noscript>
            <div
              class="video-section__media"
              style="--ratio-percent: 177.77777777777777%;"><video
                playsinline="playsinline" controls="controls" preload="metadata"
                poster="//www.shakeitprot.fr/cdn/shop/files/preview_images/25cd44eab3b64ecea3f16f6889dde18b.thumbnail.0000000000_1100x.jpg?v=1689629400"><source
                  src="https://cdn.shopify.com/videos/c/vp/25cd44eab3b64ecea3f16f6889dde18b/25cd44eab3b64ecea3f16f6889dde18b.HD-1080p-4.8Mbps-16352047.mp4"
                  type="video/mp4"><img
                  src="//www.shakeitprot.fr/cdn/shop/files/preview_images/25cd44eab3b64ecea3f16f6889dde18b.thumbnail.0000000000_1100x.jpg?v=1689629400"></video>
            </div>
          </noscript>
        </div>
      </div>

    </section><section
      id="shopify-section-template--19280186999107__29c88c90-7c7b-4d20-9986-500b8033fb8e"
      class="shopify-section section"><link
        href="//www.shakeitprot.fr/cdn/shop/t/2/assets/section-contact-form.css?v=124756058432495035521689599137"
        rel="stylesheet" type="text/css" media="all" />
      <style data-shopify>.section-template--19280186999107__29c88c90-7c7b-4d20-9986-500b8033fb8e-padding {
    padding-top: 27px;
    padding-bottom: 27px;
  }

  @media screen and (min-width: 750px) {
    .section-template--19280186999107__29c88c90-7c7b-4d20-9986-500b8033fb8e-padding {
      padding-top: 36px;
      padding-bottom: 36px;
    }
  }</style><div class="color-background-1 gradient">
        <div
          class="contact page-width page-width--narrow section-template--19280186999107__29c88c90-7c7b-4d20-9986-500b8033fb8e-padding"><h2
            class="title title-wrapper--no-top-margin inline-richtext h1 scroll-trigger animate--slide-in">
            Remplissez ce formulaire et nous vous contacterons !
          </h2><form method="post" action="/contact#ContactForm"
            id="ContactForm" accept-charset="UTF-8"
            class="isolate scroll-trigger animate--slide-in"><input
              type="hidden" name="form_type" value="contact" /><input
              type="hidden" name="utf8" value="✓" /><div class="contact__fields">
              <div class="field">
                <input
                  class="field__input"
                  autocomplete="name"
                  type="text"
                  id="ContactForm-name"
                  name="contact[Nom]"
                  value
                  placeholder="Nom">
                <label class="field__label" for="ContactForm-name">Nom</label>
              </div>
              <div class="field field--with-error">
                <input
                  autocomplete="email"
                  type="email"
                  id="ContactForm-email"
                  class="field__input"
                  name="contact[email]"
                  spellcheck="false"
                  autocapitalize="off"
                  value
                  aria-required="true"
                  placeholder="E-mail">
                <label class="field__label" for="ContactForm-email">E-mail
                  <span aria-hidden="true">*</span></label></div>
            </div>
            <div class="field">
              <input
                type="tel"
                id="ContactForm-phone"
                class="field__input"
                autocomplete="tel"
                name="contact[Numéro de téléphone]"
                pattern="[0-9\-]*"
                value
                placeholder="Numéro de téléphone">
              <label class="field__label" for="ContactForm-phone">Numéro de
                téléphone</label>
            </div>
            <div class="field">
              <textarea
                rows="10"
                id="ContactForm-body"
                class="text-area field__input"
                name="contact[Commentaire]"
                placeholder="Commentaire"></textarea>
              <label class="form__label field__label" for="ContactForm-body">Commentaire</label>
            </div>
            <div class="contact__button">
              <button type="submit" class="button">
                Envoyer
              </button>
            </div></form></div>
      </div>

    </section>
  </main>

