<div class="flex h-screen p-8">
    <div class="m-auto w-full sm:w-1/2 lg:w-1/3">
      <div class="bg-white p-8 shadow-md rounded-md">
        <h2 class="text-3xl font-extrabold mb-16">Ajouter un utilisateur</h2>
        <form [formGroup]="form" (ngSubmit)="createUser()">
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>Login</mat-label>
              <input matInput placeholder="Login" formControlName="login">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>Mot de passe</mat-label>
              <input matInput placeholder="Mot de passe" type="password" formControlName="password">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>Nom</mat-label>
              <input matInput placeholder="Nom" formControlName="name">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>Prénom</mat-label>
              <input matInput placeholder="Prénom" formControlName="lastname">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field class="m-2">
              <mat-label>Statut </mat-label>
              <mat-select formControlName="status">
                  <mat-option *ngFor="let item of status" [value]="item">
                    {{ item }}
                  </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div formArrayName="machines">
            <div
                *ngFor="let machineField of MachineFormControls; let i=index"
                [formGroupName]="i">
                <div class="mb-4 flex row">
                    <div class="w-8/12 m-1">
                        <label class="ml-4 text-2xl	font-semibold">Machine</label>
                        <select formControlName="machine" required class="w-full">
                            <option *ngFor="let machine of machines" [value]="machine.id">
                                {{ machine.label }}
                            </option>
                        </select>
                    </div>
                    <div class="w-1/12 p-4 mt-6">
                        <button mat-icon-button class="mt-4"
                            matTooltip="Add More Products"
                            color="primary" type="button"
                            (click)="addMoreMachine()" *ngIf="i === MachineFormControls.length -1">
                            <mat-icon>add</mat-icon>
                        </button>
                        <button mat-icon-button class="mt-4" matTooltip="Remove Product" color="primary" type="button"
                            (click)="deleteMachine(i)" *ngIf="i !== MachineFormControls.length -1">
                            <mat-icon>delete_outline</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
          <button type="submit"
            class="w-full bg-teal-400 text-black border-2 border-black font-bold py-2 px-4 rounded-md ">{{ 'MODULES.ADMINISTRATION.BUILDING.FORM.SUBMIT' | translate }}
          </button>
            <button type="button"
              (click)="cancel()"
              class="w-full text-black border-2 border-black font-bold py-2 px-4 rounded-md ">{{ 'MODULES.ADMINISTRATION.BUILDING.FORM.CANCEL' | translate }}
            </button>
        </form>
      </div>
    </div>
  </div>