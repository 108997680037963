import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BuildingService {

  protected URL: string = environment.backendApi;
  constructor(private httpClient: HttpClient, private storage: StorageService) { }

  public getToken(){
    let data = this.storage.get('user');
    return data;
   }

  public createBuilding(building: any): Observable<any> {
    let loginData = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/x-www-form-urlencoded',
        'enctype': 'multipart/form-data',
        'Authorization': `Bearer ${loginData.token}`
      })
    };
      return this.httpClient.post<any>(this.URL+'/building', building, httpOptions);
  }

  public getBuildings(machineCodes: []): Observable<any> {
    let loginData = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loginData.token}`
      })
    };
    var query = '';
    if (machineCodes) {
      query = '?machineCode=' + machineCodes.join();
    }
    return this.httpClient.get<any>(`${this.URL}/buildings${query}`, httpOptions);
  }
}
