<h1 class="title">{{ 'MODULES.ADMINISTRATION.HELP.TITLE' | translate }}</h1>
<div class="video-section">
    <h3 class="title">{{ 'MODULES.ADMINISTRATION.HELP.INSTALLATION' | translate }}</h3>
    <video controls>
        <source src="/assets/videos/installation.mp4" type="video/mp4">
        {{ 'MODULES.ADMINISTRATION.HELP.ERROR' | translate }}
    </video>
</div>
<div class="video-section">
    <h3 class="title">{{ 'MODULES.ADMINISTRATION.HELP.START' | translate }}</h3>
    <video controls>
        <source src="/assets/videos/start.mp4" type="video/mp4">
        {{ 'MODULES.ADMINISTRATION.HELP.ERROR' | translate }}
    </video>
</div>
<div class="video-section">
    <h3 class="title">{{ 'MODULES.ADMINISTRATION.HELP.BAC_FILLING' | translate }}</h3>
    <video width="320" height="240" controls>
        <source src="/assets/videos/remplissage_bacs.mp4" type="video/mp4">
        {{ 'MODULES.ADMINISTRATION.HELP.ERROR' | translate }}
    </video>
</div>
<div class="video-section">
    <h3 class="title">{{ 'MODULES.ADMINISTRATION.HELP.CLEAN' | translate }}</h3>
    <video width="320" height="240" controls>
        <source src="/assets/videos/netoyage.mp4" type="video/mp4">
        {{ 'MODULES.ADMINISTRATION.HELP.ERROR' | translate }}
    </video>
</div>
<div class="video-section">
    <h3 class="title">{{ 'MODULES.ADMINISTRATION.HELP.MOBILE_APP' | translate }}</h3>
    <video width="320" height="240" controls>
        <source src="/assets/videos/mobile_app.mp4" type="video/mp4">
        {{ 'MODULES.ADMINISTRATION.HELP.ERROR' | translate }}
    </video>
</div>
