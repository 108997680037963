import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { MonthlyStat, ProductStat, Stat } from '../models/stat.model';

@Injectable({
  providedIn: 'root'
})
export class StatService {

  protected URL: string = environment.backendApi;
  constructor(private httpClient: HttpClient, private storage: StorageService) { }

  public getStats(): Observable<Stat[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
      return this.httpClient.get<Stat[]>(`${this.URL}/stats`, httpOptions);
  }

  public getMonthlyStats(): Observable<MonthlyStat[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
      return this.httpClient.get<MonthlyStat[]>(`${this.URL}/monthly-stats`, httpOptions);
  }

  public getProductStats(): Observable<ProductStat[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
      return this.httpClient.get<ProductStat[]>(`${this.URL}/stats/product`, httpOptions);
  }

  public getStatsByMachine(machineId: string): Observable<Stat> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
      return this.httpClient.get<Stat>(`${this.URL}/stats/${machineId}`, httpOptions);
  }

  public getProductStatsByMachine(machineId: string): Observable<ProductStat[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
      return this.httpClient.get<ProductStat[]>(`${this.URL}/stats/${machineId}/product`, httpOptions);
  }
}
