 <!-- BEGIN sections: footer-group -->
<div id="shopify-section-sections--19280187490627__footer"
    class="shopify-section shopify-section-group-footer-group"
    *ngIf="router.url !== '/login' && router.url!=='/not-found'">
    <footer
        class="footer color-background-1 gradient section-sections--19280187490627__footer-padding">
        <div
            class="footer__content-top page-width">
            <div
                class="footer__blocks-wrapper grid grid--1-col grid--2-col grid--4-col-tablet  scroll-trigger animate--slide-in"
                data-cascade>
                <div
                    class="footer-block grid__item footer-block--menu scroll-trigger animate--slide-in"
                    data-cascade
                    style="--animation-order: 1;">
                </div>
                <div class="footer-block grid__item scroll-trigger animate--slide-in"
                    data-cascade
                    style="--animation-order: 2;">
                </div>
                <div class="footer-block grid__item scroll-trigger animate--slide-in" data-cascade style="--animation-order: 3;">
                    <h2 class="footer-block__heading inline-richtext">Nous contacter !</h2>
                    <div class="footer-block__details-content rte">
                        <p>email : contact@shakeitprot.fr</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer__content-bottom scroll-trigger animate--slide-in" data-cascade>
            <div class="footer__content-bottom-wrapper page-width">
                <div class="footer__column footer__localization isolate"></div>
                <div class="footer__column footer__column--info">
                    <small>Site version : 2.7.1</small>
                </div>
            </div>
            <div class="footer__content-bottom-wrapper page-width">
                <div class="footer__copyright caption">
                    <small class="copyright__content">&copy; 2023, 
                        <a href="/" title>SHAKE&#39;IT PROT</a>
                    </small>
                </div>
            </div>
        </div>
    </footer>
</div>
<!-- END sections: footer-group -->
