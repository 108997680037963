<div *ngIf="mail">
    <div class="mail-info">
        <div class="mail__info">
            <h2>{{ mail.title }}</h2>
            <p>{{ mail.mailto }}</p>
            <p>{{ mail.date }}</p>
            <div>{{ mail.body }}</div>
        </div>
    </div>
</div>

