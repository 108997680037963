import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  protected URL: string = environment.backendApi;
  constructor(private httpClient: HttpClient, private storage: StorageService) { }

  public getToken(){
    let data = this.storage.get('user');
    return data;
   }

  public createTicket(ticket: any): Observable<any> {
    let loginData = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/x-www-form-urlencoded',
        'enctype': 'multipart/form-data',
        'Authorization': `Bearer ${loginData.token}`
      })
    };
      return this.httpClient.post<any>(this.URL+'/admin/ticket/create', ticket, httpOptions);
  }

  public getAllTickets(): Observable<any> {
    let loginData = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loginData.token}`
      })
    };
      return this.httpClient.get<any>(`${this.URL}/admin/ticket/list`, httpOptions);
  }
 

  public getTicket(ticketId : string): Observable<any> {
    let loginData = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loginData.token}`
      })
    };
      return this.httpClient.get<any>(`${this.URL}/admin/ticket/${ticketId}`, httpOptions);
  }

  public updateTicket(ticket: any): Observable<any> {
    let loginData = this.getToken();
    const body ={
      status: ticket.status
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/x-www-form-urlencoded',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loginData.token}`
      })
    };
      return this.httpClient.put<any>(`${this.URL}/admin/ticket/${ticket.id}`, body, httpOptions);
  }

  public deleteTicket(ticketId: any): Observable<any> {
    let loginData = this.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': `Bearer ${loginData.token}`
      })
    };
      return this.httpClient.delete<any>(`${this.URL}/admin/ticket/${ticketId}`, httpOptions);
  }
}
