import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { TicketService } from '../../../service/ticket.service';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { DialogContentComponent } from '../../dialog-content/dialog-content.component';
import { SnackbarService } from 'src/app/services/snackbar.service';


export interface TicketElement {
  id: number;
  title: string;
  subtext: string;
  status: string;
  date: string;
}

@Component({
  selector: 'app-list-ticket',
  templateUrl: './list-ticket.component.html',
  styleUrls: ['./list-ticket.component.scss']
})

export class ListTicketComponent implements OnInit {
  @ViewChild(MatTable, { static: true }) table: MatTable<any> = Object.create(null);
  searchText: any;
  totalCount = 0;
  inProcess = 0;
  alreadyProcessed = 0;
  unprocessed = 0;
  userData: any;
  displayedColumns: string[] = [
    'id',
    'title',
    'status',
    'machine',
    'date',
    'actions',
  ];
  ticketData: any;
  dataSource = new MatTableDataSource();

  constructor(private dialog: MatDialog, public ticketService: TicketService, private router: Router, private storage: StorageService, private snackBar: SnackbarService) { }

  ngOnInit(): void {
    this.ticketService.getAllTickets().subscribe({
      next: (tickets) => {
        this.dataSource.data = tickets;
        this.dataSource._updateChangeSubscription();
        this.totalCount = this.dataSource.data.length;
        this.unprocessed = this.btnCategoryClick('Unprocessed');
        this.inProcess = this.btnCategoryClick('In process');
        this.alreadyProcessed = this.btnCategoryClick('Already processed');
      },
      error: (e) => console.error(e)
    });
    
    this.userData = this.storage.get('user');
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public isAdmin (): boolean {
      return this.userData?.status == 'ADMIN' || this.userData?.status == 'SUPERADMIN';
  }

  public formatStatus (status: string): string {
      if (status == 'Unprocessed') {
        return 'En attente de traitement';
      }
      if (status == 'In process') {
        return 'En cours';
      }
      if (status == 'Already processed') {
        return 'Terminé';
      }
      return status;
  }

  btnCategoryClick(val: string): number {
    this.dataSource.filter = val.trim().toLowerCase();
    return this.dataSource.filteredData.length;
  }

  getTicketByTicketId(action: string, id: string): void {
    this.ticketData = null;
    this.ticketService.getTicket(id).subscribe({
      next: (tickets) => {
        this.ticketData = tickets;
        this.ticketData['action'] = action;
      },
      error: (e) => console.error(e)
    });
  }

  openDialog(action: string, obj?: any): void {
    if (action !== 'Add') {
      this.getTicketByTicketId(action, obj.id);
    }
    setTimeout(() => { 
      if (this.ticketData || action === 'Add') {
        const dialogRef = this.dialog.open(DialogContentComponent, {
        data: {data: obj ? this.ticketData : action, type: 'ticket'},
        disableClose: false,
        width: '600px',
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result.event === 'Add') {
          this.addRowData(result.data);
        } else if (result.event === 'Update') {
          this.updateRowData(result.data);
        } else if (result.event === 'Delete') {
          this.deleteRowData(result.data);
        } else if (result.event === 'View') {
          this.viewRowData(result.data);
        }
      });
    }
    }, 3000);
  }

  reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/admin/ticket']);
}
  // tslint:disable-next-line - Disables all
  addRowData(data: any): void {
    let formData = new FormData();
      formData.append('title', data.title);
      formData.append('machine_id', data.machine_id);
      formData.append('description', data.description);
      formData.append('image', data.image);
      this.ticketService.createTicket(formData).subscribe({
        next: (ticket) => {
          this.snackBar.openSnackBar('Le ticket ' + data.title + ' a été créé', '', 5000)
          this.reloadComponent();
        },
        error: (e) => this.snackBar.openSnackBar(e.error.message, 'Erreur', 5000)

      });
  }

  // tslint:disable-next-line - Disables all
  updateRowData(row_obj: any): boolean | any {
    this.ticketService.updateTicket(row_obj).subscribe({
      next: (ticket) => {
        this.snackBar.openSnackBar('Le ticket a été modifié', '', 5000)
        this.reloadComponent();
      },
      error: (e) => this.snackBar.openSnackBar(e.error.message, 'Erreur', 5000)
    }); 
  }

  // tslint:disable-next-line - Disables all
  deleteRowData(row_obj: any): boolean | any {
    this.ticketService.deleteTicket(row_obj.id).subscribe({
      next: (ticket) => {
        this.snackBar.openSnackBar('Le ticket a été supprimé', '', 5000)
        this.reloadComponent();      },
      error: (e) => this.snackBar.openSnackBar(e.error.message, 'Erreur', 5000)
    });
  }

  viewRowData(row_obj: TicketElement) {
  }
}

