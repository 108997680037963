
<div id="main">
    <div class="fof">
            <h1>Erreur 404</h1>
            <p>La page que vous cherchez n'existe pas !</p>
            <a href="/" class="link_404">Aller à la maison</a>
    </div>
</div>



