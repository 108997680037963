import { CUSTOM_ELEMENTS_SCHEMA, NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { HeaderComponent } from './modules/common/header/header.component';
import { FooterComponent } from './modules/common/footer/footer.component';
import { NotFoundComponent } from './modules/common/not-found/not-found.component';
import { HomeComponent } from './modules/home/home.component';
import { LoginPageComponent } from './modules/account/views/login-component/login-page.component';

import { AccountModule } from './modules/account/account.module';
import { AdministrationModule } from './modules/administration/administration.module';

import { LoginService } from './modules/account/views/services/login.service';
import { MachineService } from './modules/administration/service/machine.service';

import { MaterialModule } from './material.module';

import { ErrorInterceptor } from './services/error.interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SpinnerInterceptorInterceptor } from './services/spinner-interceptor.interceptor';

registerLocaleData(localeFr);

const appRoutes: Routes = [
  { path: '', component: LoginPageComponent },
  { path: 'login', component: LoginPageComponent },
  { path: 'admin', loadChildren: () => import('./modules/administration/administration.module').then(m => m.AdministrationModule) },
  { path: 'not-found', component: NotFoundComponent },
  { path: '**', redirectTo: 'not-found' }
];

const interceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptorInterceptor, multi: true }, 
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }

]

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    CommonModule,
    RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' }),
    TranslateModule.forRoot({
      defaultLanguage: 'fr-FR',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    AccountModule,
    AdministrationModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    LoginService,
    MachineService,
    interceptorProviders,
    { provide: LOCALE_ID, useValue: "fr-FR" }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
