<div *ngIf="user">
    <div class="user-info">
        <div class="user__info">
            <table>
                <tbody>
                    <tr>
                        <td>Login</td>
                        <td>{{ user.login }}</td>
                    </tr>
                    <tr>
                        <td>Nom</td>
                        <td>{{ user.name }}</td>
                    </tr>
                    <tr>
                        <td>Prénom</td>
                        <td>{{ user.lastname }}</td>
                    </tr>
                    <tr>
                        <td>status</td>
                        <td>{{ user.status }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

