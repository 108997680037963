<div class="user">
    <div class="user-info">
        <h2 class="title">Users</h2>
    </div>
    <div>
        <button
            *ngIf="isSuperAdmin()"
            class="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline m text-lg"
            color="primary" mat-raised-button
            (click)="addUser()">
            Ajouter un utilisateur
        </button>
    </div>
    <div class="user__list">
        <div class="table-container">
            <table mat-table [dataSource]="users">
                <ng-container matColumnDef="login" class="table-header">
                    <th mat-header-cell *matHeaderCellDef>Login</th>
                    <td mat-cell *matCellDef="let element">{{ element.login }}</td>
                </ng-container>
                <ng-container matColumnDef="firstname">
                    <th mat-header-cell *matHeaderCellDef>Prénom</th>
                    <td mat-cell *matCellDef="let element" class="m-4">{{ element.name }}</td>
                </ng-container>
                <ng-container matColumnDef="lastname">
                    <th mat-header-cell *matHeaderCellDef>Nom</th>
                    <td mat-cell *matCellDef="let element" class="m-4">{{ element.lastname }}</td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element" class="m-4">{{ element.status }}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selectUser(row)"></tr>
            </table>
        </div>
    </div>
</div>

