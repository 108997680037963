<div class="mail">
    <div class="mail-info">
        <h2 class="title">Mails</h2>
    </div>
    <div class="mail-filter" *ngIf="isAdmin()">
        <select (change)="filterMachine($event)" class="date-selection">
            <option [value]="0">
                Toutes les salles
            </option>
            <option *ngFor="let machine of machines" [value]="machine.id">
                {{ machine.label }}
            </option>
        </select>
    </div>
    <div class="mail__list">
        <div class="table-container2">
            <table mat-table [dataSource]="selectedMails">
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef>Titre</th>
                    <td mat-cell *matCellDef="let element" class="m-4">{{ element.title }}</td>
                </ng-container>
                <ng-container matColumnDef="mailto">
                    <th mat-header-cell *matHeaderCellDef>Destinataire</th>
                    <td mat-cell *matCellDef="let element" class="m-4">{{ element.mailto }}</td>
                </ng-container>
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>Date</th>
                    <td mat-cell *matCellDef="let element" class="m-4">{{ element.date | date:'short' }}</td>
                </ng-container>
                <ng-container *ngIf="isAdmin() || hasMultipleMachine()" matColumnDef="machine">
                    <th mat-header-cell *matHeaderCellDef>Salle</th>
                    <td mat-cell *matCellDef="let element" class="m-4">{{ displayMachineLabel(element.machineId) }}</td>
                </ng-container>
                <ng-container *ngIf="!isAdmin() && !hasMultipleMachine()" matColumnDef="machine">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element" class="m-4"></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selectMail(row)"></tr>
            </table>
        </div>
    </div>
</div>

