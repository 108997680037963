import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../.././environments/environment';
import { Login } from '../models/login.model';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user.model';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class LoginService {

  protected URL: string = environment.backendApi;
  public user = new BehaviorSubject('');


  constructor(private httpClient: HttpClient) { }

    public login(login: any): Observable<any> {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        })
      };
        return this.httpClient.post<User>(this.URL+'/user/login', login, httpOptions);
    }

    public register(login: Login): Observable<User> {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        })
      };
        return this.httpClient.post<User>(this.URL+'/user', login, httpOptions);
    }
}
