
<div
  class="shopify-section shopify-section-group-header-group section-header"
  *ngIf="router.url !== '/login' && router.url!=='/not-found'">

  <header
    class="header header--top-center header--mobile-center page-width header--has-menu header--has-social">

    <details class="menu-drawer-container">
      <summary
        class="header__icon header__icon--menu header__icon--summary link focus-inset"
        aria-label="Menu">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            class="icon icon-hamburger"
            fill="none"
            viewBox="0 0 18 16">
            <path
              d="M1 .5a.5.5 0 100 1h15.71a.5.5 0 000-1H1zM.5 8a.5.5 0 01.5-.5h15.71a.5.5 0 010 1H1A.5.5 0 01.5 8zm0 7a.5.5 0 01.5-.5h15.71a.5.5 0 010 1H1a.5.5 0 01-.5-.5z"
              fill="currentColor" />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            class="icon icon-close"
            fill="none"
            viewBox="0 0 18 17">
            <path
              d="M.865 15.978a.5.5 0 00.707.707l7.433-7.431 7.579 7.282a.501.501 0 00.846-.37.5.5 0 00-.153-.351L9.712 8.546l7.417-7.416a.5.5 0 10-.707-.708L8.991 7.853 1.413.573a.5.5 0 10-.693.72l7.563 7.268-7.418 7.417z"
              fill="currentColor" />
          </svg>

        </span>
      </summary>
      <div class="gradient menu-drawer motion-reduce">
        <div class="menu-drawer__inner-container">
          <div class="menu-drawer__navigation-container">
            <nav class="menu-drawer__navigation">
              <ul class="menu-drawer__menu has-submenu list-menu" role="list">
                <li *ngIf="user && user.status === 'MANAGER' && user?.machineCode !== ''">
                  <a 
                    id="HeaderDrawer-accueil"
                    href="/"
                    [routerLink]="['/admin/machine/'+user.machineCode]"
                    [routerLinkActive]="['header__active-menu-item']"
                    class="list-menu__item link link--text"
                    aria-current="page">
                      machine
                  </a>
                </li>
                <li *ngIf="isAdmin()">
                  <a
                    id="HeaderMenu-machine"
                    href="/admin"
                    [routerLink]="['/admin/machines']"
                    [routerLinkActive]="['header__active-menu-item']"
                    class="header__menu-item list-menu__item link link--text focus-inset">
                    <span>Machines</span>
                  </a>
                </li>
                <li *ngIf="isAdmin()">
                  <a
                    id="HeaderMenu-machine"
                    href="/admin"
                    [routerLink]="['/admin/stat']"
                    [routerLinkActive]="['header__active-menu-item']"
                    class="header__menu-item list-menu__item link link--text focus-inset">
                    <span>Stat</span>
                  </a>
                </li>
                <li>
                  <a
                    id="HeaderMenu-machine"
                    href="/admin"
                    [routerLink]="['/admin/client']"
                    [routerLinkActive]="['header__active-menu-item']"
                    class="header__menu-item list-menu__item link link--text focus-inset">
                    <span>Clients</span>
                  </a>
                </li>
                <li *ngIf="user">
                  <a
                    id="HeaderMenu-ticket"
                    href="/admin"
                    [routerLink]="['/admin/ticket']"
                    [routerLinkActive]="['header__active-menu-item']"
                    class="header__menu-item list-menu__item link link--text focus-inset">
                    <span>Mes tickets</span>
                  </a>
                </li>
                <li *ngIf="isAdmin()">
                  <a id="HeaderMenu-machine" href="/admin/invoice" [routerLink]="['/admin/invoice']"
                    [routerLinkActive]="['header__active-menu-item']"
                    class="header__menu-item list-menu__item link link--text focus-inset">
                    <span>Factures</span>
                  </a>
                </li>
                <li *ngIf="user">
                  <a id="HeaderMenu-machine" href="/admin/command" [routerLink]="['/admin/command']"
                    [routerLinkActive]="['header__active-menu-item']"
                    class="header__menu-item list-menu__item link link--text focus-inset">
                    <span>Commandes</span>
                  </a>
                </li>
                <li *ngIf="user">
                  <a id="HeaderMenu-machine" href="/admin/help" [routerLink]="['/admin/help']"
                    [routerLinkActive]="['header__active-menu-item']"
                    class="header__menu-item list-menu__item link link--text focus-inset">
                    <span>Aide</span>
                  </a>
                </li>
                <li *ngIf="user">
                  <a id="HeaderMenu-machine" href="/admin/account" [routerLink]="['/admin/account']"
                    [routerLinkActive]="['header__active-menu-item']"
                    class="header__menu-item list-menu__item link link--text focus-inset">
                    <span>Mon compte</span>
                  </a>
                </li>
                <li *ngIf="!user">
                  <a
                    id="HeaderMenu-machine"
                    (click)="auth('login')"
                    style="cursor: pointer;"
                    class="header__menu-item list-menu__item link link--text focus-inset">
                    <span>Se connecter</span>
                  </a>
                </li>
                <li *ngIf="user">
                  <a
                    id="HeaderMenu-machine"
                    (click)="auth('logout')"
                    style="cursor: pointer;"
                    class="header__menu-item list-menu__item link link--text focus-inset">
                    <span>Déconnexion</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </details>

    <nav class="header__inline-menu">
      <h1 class="header__heading"><a href="/"
          class="header__heading-link link link--text focus-inset"><div
            class="header__heading-logo-wrapper">
            <img
              src="/assets/images/logo2.png"
              alt="SHAKE&#39;IT PROT"
              width="260" height="78.23560767590618" loading="eager"
              class="header__heading-logo motion-reduce"
              sizes="(max-width: 520px) 50vw, 260px">
          </div></a></h1>
      <ul class="list-menu list-menu--inline" role="list">
        <li *ngIf="user && user.status === 'MANAGER' && user?.machineCode !== '' && user?.machineCode.length === 1">
          <a
            id="HeaderMenu-accueil"
            href="/"
            [routerLink]="['/admin/machine/'+user.machineCode]"
            [routerLinkActive]="['header__active-menu-item']"
            class="header__menu-item list-menu__item link link--text focus-inset"
            aria-current="page">
            <span>Ma machine</span>
          </a>
        </li>
        <li *ngIf="isAdmin() || (user?.status === 'MANAGER' && user?.machineCode.length > 1) ">
          <a
            id="HeaderMenu-machine"
            href="/admin"
            [routerLink]="['/admin/machines']"
            [routerLinkActive]="['header__active-menu-item']"
            class="header__menu-item list-menu__item link link--text focus-inset">
            <span>Machines</span>
          </a>
        </li>
        <li *ngIf="isAdmin()">
          <a
            id="HeaderMenu-machine"
            href="/admin"
            [routerLink]="['/admin/stat']"
            [routerLinkActive]="['header__active-menu-item']"
            class="header__menu-item list-menu__item link link--text focus-inset">
            <span>Stat</span>
          </a>
        </li>
        <li>
          <a
            id="HeaderMenu-machine"
            href="/admin"
            [routerLink]="['/admin/client']"
            [routerLinkActive]="['header__active-menu-item']"
            class="header__menu-item list-menu__item link link--text focus-inset">
            <span>Client</span>
          </a>
        </li>
        <li *ngIf="user">
          <a
            id="HeaderMenu-ticket"
            href="/admin"
            [routerLink]="['/admin/ticket']"
            [routerLinkActive]="['header__active-menu-item']"
            class="header__menu-item list-menu__item link link--text focus-inset">
            <span>Mes tickets</span>
          </a>
        </li>
        <li *ngIf="isAdmin()">
          <a id="HeaderMenu-machine" href="/admin/invoice" [routerLink]="['/admin/invoice']"
            [routerLinkActive]="['header__active-menu-item']"
            class="header__menu-item list-menu__item link link--text focus-inset">
            <span>Factures</span>
          </a>
        </li>
        <li *ngIf="user">
          <a id="HeaderMenu-machine" href="/admin/command" [routerLink]="['/admin/command']"
            [routerLinkActive]="['header__active-menu-item']"
            class="header__menu-item list-menu__item link link--text focus-inset">
            <span>Commandes</span>
          </a>
        </li>
        <li *ngIf="isSuperAdmin()">
          <a id="HeaderMenu-machine" href="/admin/mail" [routerLink]="['/admin/mail']"
            [routerLinkActive]="['header__active-menu-item']"
            class="header__menu-item list-menu__item link link--text focus-inset">
            <span>Mail</span>
          </a>
        </li>
        <li *ngIf="isSuperAdmin()">
          <a id="HeaderMenu-machine" href="/admin/log" [routerLink]="['/admin/log']"
            [routerLinkActive]="['header__active-menu-item']"
            class="header__menu-item list-menu__item link link--text focus-inset">
            <span>Logs</span>
          </a>
        </li>
        <li *ngIf="user">
          <a id="HeaderMenu-machine" href="/admin/help" [routerLink]="['/admin/help']"
            [routerLinkActive]="['header__active-menu-item']"
            class="header__menu-item list-menu__item link link--text focus-inset">
            <span>Aide</span>
          </a>
        </li>
        <li *ngIf="isSuperAdmin()">
          <a id="HeaderMenu-machine" href="/admin/users" [routerLink]="['/admin/users']"
            [routerLinkActive]="['header__active-menu-item']"
            class="header__menu-item list-menu__item link link--text focus-inset">
            <span>Utilisateurs</span>
          </a>
        </li>
        <li *ngIf="user">
          <a id="HeaderMenu-machine" href="/admin/account" [routerLink]="['/admin/account']"
            [routerLinkActive]="['header__active-menu-item']"
            class="header__menu-item list-menu__item link link--text focus-inset">
            <span>Mon compte</span>
          </a>
        </li>
        <li *ngIf="!user">
          <a
            id="HeaderMenu-machine"
            (click)="auth('login')"
            style="cursor: pointer;"
            class="header__menu-item list-menu__item link link--text focus-inset">
            <span>Se connecter</span>
          </a>
        </li>
        <li *ngIf="user">
          <a
            id="HeaderMenu-machine"
            (click)="auth('logout')"
            style="cursor: pointer;"
            class="header__menu-item list-menu__item link link--text focus-inset">
            <span>Déconnexion</span>
          </a>
        </li>
      </ul>
    </nav>
  </header>

</div>
<!-- END sections: header-group -->