import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from '../../../service/user.service';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  public users: User[] = [];
  public user: any;

  displayedColumns: string[] = [
    'login',
    'firstname',
    'lastname',
    'status'
  ];

  constructor(private userService: UserService, private storage: StorageService, private _router: Router) {
  }

  ngOnInit() {
    this.user = this.storage.get('user');

    this.userService.getUsers().subscribe({
      next: (users) => {
        this.users = users.filter(user => user.status === 'MANAGER' || user.status === 'ADMIN' || user.status === 'SUPERADMIN');
      },
      error: (e) => console.error(e)
    });
  }

  public isAdmin (): boolean {
      return this.user?.status == 'ADMIN' || this.user?.status == 'SUPERADMIN';
  }

  public isSuperAdmin (): boolean {
      return this.user?.status == 'SUPERADMIN';
  }

  public hasMultipleMachine (): boolean {
    return this.user?.machineCode.length > 1;
  }

  public selectUser (user: User): void {
    this._router.navigate(
      ['admin/users', user.id]
    );
  }

  public addUser (): void {
      this._router.navigate(
          ['admin/users/new']
      );
  }

}

