import { Component, Input } from '@angular/core';
import { LoginService } from '../../account/views/services/login.service';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
   @Input() user: any;

  public isUserAvailable: boolean = true;
  constructor(public loginService: LoginService, public router: Router, private storage: StorageService) { 
    this.user = null;
  }

  auth(func: string){
    if(func === 'logout'){
      this.loginService.user.next('');
      this.storage.remove('user');
      this.router.navigate(['/login']);
    }else {
      this.router.navigate(['/login']);
    }
  }

  public isAdmin (): boolean {
    return this.user?.status == 'ADMIN' || this.user?.status == 'SUPERADMIN';
  }

  public isSuperAdmin (): boolean {
    return this.user?.status == 'SUPERADMIN';
  }

}
