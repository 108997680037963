<div class="flex h-screen p-8">
    <div class="m-auto w-full sm:w-1/2 lg:w-1/3">
      <div class="bg-white p-8 shadow-md rounded-md">
        <h2 class="text-3xl font-extrabold mb-16">{{ 'MODULES.ADMINISTRATION.BUILDING.FORM.TITLE' | translate }}</h2>
        <form [formGroup]="form" (ngSubmit)="createBuilding()">
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.BUILDING.FORM.CODE_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.BUILDING.FORM.CODE_PLACEHOLDER' | translate }}" formControlName="code">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.BUILDING.FORM.NAME_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.BUILDING.FORM.NAME_PLACEHOLDER' | translate }}" formControlName="name">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.BUILDING.FORM.CITY_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.BUILDING.FORM.CITY_PLACEHOLDER' | translate }}" formControlName="city">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>{{ 'MODULES.ADMINISTRATION.BUILDING.FORM.POSTAL_CODE_INPUT' | translate }}</mat-label>
              <input matInput placeholder="{{ 'MODULES.ADMINISTRATION.BUILDING.FORM.POSTAL_CODE_PLACEHOLDER' | translate }}" formControlName="postalCode">
            </mat-form-field>
          </div>
          <div class="mb-4">
            <mat-form-field class="m-2">
              <mat-label>{{ 'MODULES.ADMINISTRATION.BUILDING.FORM.SYSTEM' | translate }} </mat-label>
              <mat-select formControlName="system">
                  <mat-option *ngFor="let system of systems" [value]="system">
                    {{ system }}
                  </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <button type="submit"
            class="w-full bg-teal-400 text-black border-2 border-black font-bold py-2 px-4 rounded-md ">{{ 'MODULES.ADMINISTRATION.BUILDING.FORM.SUBMIT' | translate }}
          </button>
            <button type="button"
              (click)="cancel()"
              class="w-full text-black border-2 border-black font-bold py-2 px-4 rounded-md ">{{ 'MODULES.ADMINISTRATION.BUILDING.FORM.CANCEL' | translate }}
            </button>
        </form>
      </div>
    </div>
  </div>