import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { Mail } from '../models/mail.model';

@Injectable({
  providedIn: 'root'
})
export class MailService {

    protected URL: string = environment.backendApi;
    constructor(private httpClient: HttpClient, private storage: StorageService) { }

    public getAllMail(): Observable<Mail[]> {
        const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
        };
        return this.httpClient.get<Mail[]>(`${this.URL}/mails`, httpOptions);
    }
 

    public getMailById(mailId : string): Observable<Mail> {
        const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        })
        };
        return this.httpClient.get<Mail>(`${this.URL}/mails/${mailId}`, httpOptions);
    }
}
