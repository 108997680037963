import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { BuildingService } from '../../../../service/building.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-building-add',
  templateUrl: './building-add.component.html',
  styleUrls: ['./building-add.component.scss']
})
export class BuildingAddComponent {

    public form: FormGroup;
    
    systems = ['NONE', 'HEITZ', 'SPORTIGO', 'DECIPLUS', 'VIRTUAL_GYM', 'RESAMANIA', 'FITNESS_PARK'];

    constructor(
        private _router: Router,
        public buildingService: BuildingService,
        private snackBar: SnackbarService,
        private _translateService: TranslateService
    ) {
        this.form = new FormGroup({
            code: new FormControl('', [Validators.required]),
            name: new FormControl('', [Validators.required]),
            city: new FormControl('', [Validators.required]),
            postalCode: new FormControl('', [Validators.required]),
            system: new FormControl('', [Validators.required]),
        });
    }

    public createBuilding(): void {
        if (this.form.valid) {
            let data = this.form.value;
            let formData = new FormData();
            formData.append('code', data.code);
            formData.append('name', data.name);
            formData.append('city', data.city);
            formData.append('postalCode', data.postalCode);
            formData.append('system', data.system);
            this.buildingService.createBuilding(formData).subscribe({
                next: (building) => {
                    this._router.navigate(['admin/machines']);
                    this.snackBar.openSnackBar(this._translateService.instant('MODULES.ADMINISTRATION.BUILDING.FORM.SUCCESS'), 'Success', 5000);
                },
                error: (e) => {
                    console.error(e);
                    this.snackBar.openSnackBar(e.error.message, 'Failure', 5000);
                }
            });
        }
    }

    public cancel(): void {
        this._router.navigate(
            ['admin/machines']
        );
    }

}
