import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from '../../service/user.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

    public user: any;
    public form: FormGroup;
    public isPasswordEnable: boolean = false;
    public hasFormError: boolean = false;
    public passwordNotSame: boolean = false;

    constructor(private storage: StorageService, private userService : UserService, private snackBar: SnackbarService) {
        this.form = new FormGroup({
            password: new FormControl(),
            confirmPassword: new FormControl(),
        });
    }

    ngOnInit() {
        this.user = this.storage.get('user');
    }

    public enablePasswordUpdate(): void {
        this.isPasswordEnable = true;
    }

    public cancelPasswordUpdate(): void {
        this.isPasswordEnable = false;
    }

    public updatePassword(): void {
        if (this.form.valid) {
            let password = this.form.get("password")?.value;
            let confirmPassword = this.form.get("confirmPassword")?.value;

            if (!password) {
                this.hasFormError = true;
                return;
            }

            this.hasFormError = false;

            if (password === confirmPassword) {
                this.passwordNotSame = false;

                const updatePassword = {
                    password: this.form.get("password")?.value,
                    platform: 'web'
                }

                this.userService.updatePassword(updatePassword).subscribe({
                    next: (user) => {
                        this.snackBar.openSnackBar('mot de passe modifié avec succès', '', 5000);
                        this.isPasswordEnable = false;
                    },
                    error: (e) => {
                        console.error(e);
                        this.snackBar.openSnackBar(e.error.message, 'Erreur', 5000);
                    }
                });
                
            } else {
                this.passwordNotSame = true;
            }
        } else {
            this.hasFormError = true;
        }
    }
}

